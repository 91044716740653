import { Routes, Route, useLocation } from "react-router-dom";
// import AOS from "aos";
// import "aos/dist/aos.css";
import AppContext from "./Context/AppContext";
import React, { useEffect, useState } from "react";
import * as Page from "./pages";
import GeneralCourse from "./pages/GeneralCourse";


function App() {
  const { pathname } = useLocation();
  // useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [openModal, setOpenModal] = useState(false);
  const [course, setCourse] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  function handleOpenModalWithCourseTrue() {
    setOpenModal(true);
    setCourse(true);
  }
  function handleOpenModalWithCourseFalse() {
    setOpenModal(true);
    setCourse(false);
  }
  useEffect(() => {
    const checkWidth = () => {
      if (window.innerWidth <= 600) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkWidth();
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, []);
  return (
    <AppContext.Provider
      value={{
        isMobile,
        handleOpenModalWithCourseTrue,
        handleOpenModalWithCourseFalse,
      }}
    >
      <Routes>
        <Route path="/" element={<Page.Home />} />
        <Route path="/course/:uniName/:courseName" element={<Page.Course />} />
        {/* <Route path="/course-specialization/:courseName" element={<Page.CourseSpecial />} /> */}
        <Route
          path="/explore-course-category/:name" 
          element={<Page.CourseCategoryExplore />}
        />
        <Route
          path="/general-specialization-course/:generalCourseName"
          element={<Page.GeneralSpecializationCourse />}
        />
        <Route
          path="/general-course/:courseName"
          element={<GeneralCourse />}
        />
        {/* <Route
          path="/general-specialization-course"
          element={<Page.GeneralSpecializationCourse />}
        /> */}
        <Route path="/expert/:expertId" element={<Page.Expert />} />
        <Route path="/university/:uniName" element={<Page.University />} />
        <Route path="/blog-list" element={<Page.Blog />} />
        <Route path="/blog-details/:title" element={<Page.BlogsPage />} />
        <Route path="/onlinemba/:searchTerm" element={<Page.OnlineMba />} />
        <Route path="/comparison/:universityIds" element={<Page.Comparison />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
