import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import owlStyle from "../assets/styles/ModuleStyle/customOwl.module.css";
import { Link } from "react-router-dom";
import { apiBaseUrl, baseUrl } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { setInNewsData } from "../reducer/inNewsSlice";
import { getMonthDay } from "../utils/utils";
import uniqid from "uniqid";

function InNews() {
  const dispatch = useDispatch();
  const newsData = useSelector((state) => state.inNews?.inNewsData);
  // const [newsData, setNewsData] = useState();
  const [loading, setLoading] = useState(false);
  const fetchNews = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}news-list`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          dispatch(setInNewsData(data?.news_list));
        }
      })
      .catch((err) => {
        setLoading(false);
      });

    // await fetch(`${apiBaseUrl}news-list`)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setLoading(false);
    //     if (data.status === "success") {
    //       setNewsData(data?.news_list);
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //   });
  };
  useEffect(() => {
    fetchNews();
  }, []);
  return (
    <>
      <div className="events section">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col">
              <div className="section_title">
                <h2 className="section_title">University Guroo in News</h2>
              </div>
            </div>
          </div>
          <div className="row events_row">
            {loading ? (
              <h3>Loading...</h3>
            ) : (
              <OwlCarousel
                id="UniveristyGuroo_Update"
                className="owl-carousel owl-theme row"
                loop
                nav
                dots={false}
                stageOuterClass={owlStyle.owlStageOuterSpec}
                navText={[
                  "<i class='fa fa-chevron-left'></i>",
                  "<i class='fa fa-chevron-right'></i>",
                ]}
                navContainerClass={owlStyle.containerNav}
                navClass={["navPrev", "navNext"]}
                // items={4}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 4,
                  },
                }}
              >
                {newsData &&
                  newsData.map((item, index) => (
                    <div
                      key={uniqid()}
                      className="col-lg-12 event_col item"
                      data-aos="fade-up"
                      data-aos-delay={100}
                    >
                      <div className="event event_left">
                        <div className="event_image">
                          <Link target="_blank" to={item.link}>
                            <img src={`${baseUrl}${item.image}`} alt="" />
                          </Link>
                        </div>
                        <div className="event_body d-flex flex-row align-items-start justify-content-start">
                          <div className="event_date">
                            <div className="d-flex flex-column align-items-center justify-content-center trans_200">
                              <div className="event_day trans_200">
                                {getMonthDay(item.date_time, "day")}
                              </div>
                              <div className="event_month trans_200">
                                {getMonthDay(item.date_time, "month")}
                              </div>
                            </div>
                          </div>
                          <div className="event_content">
                            <div className="event_title">
                              <Link target="_blank" to={item.link}>
                                {item.title}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(InNews);
