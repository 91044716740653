import React, { useEffect, useState } from "react";
import CommonLayout from "../layouts/CommonLayout";
import CourseNameDetails from "../components/CourseComponents/CourseNameDetails";
import SubHeader from "../components/CourseComponents/SubHeader";
import JulyOfferWithCarousel from "../components/CourseComponents/JulyOfferWithCarousel";
import CrStyle from "../assets/styles/ModuleStyle/course.module.css";
import GetAssistanceBox from "../components/CourseComponents/GetAssistanceBox";
import UgcAdd from "../components/CourseComponents/UgcAdd";
import CourseSpecification from "../components/CourseComponents/CourseSpecification";
import UniversitySummary from "../components/CourseComponents/UniversitySummary";
import CourseFee from "../components/CourseComponents/CourseFee";
import FacultyDetails from "../components/CourseComponents/FacultyDetails";
import CourseSideBar from "../components/CourseComponents/CourseSideBar";
import UniversityCourseCarousel from "../components/UniversityComponents/UniversityCourseCarousel";
import StudentManse2 from "../components/CourseComponents/StudentManse2";
import LatestUpdates from "../components/LatestUpdates";
import Blogs from "../components/Blogs";
import TopUniversity from "../components/TopUniversity";
import Faqs from "../components/CourseComponents/Faqs";
import WriteAReview from "../components/CourseComponents/WriteAReview";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiBaseUrl, baseUrl } from "../config";
import {
  setBannerArr,
  setUniversity,
  setUniversityId,
} from "../reducer/universitySlice";
import uniqid from "uniqid";

export default function University() {
  const { uniName } = useParams();
  const getUniversityDetails = async () => {
    await fetch(`${apiBaseUrl}univercity-details/${uniName}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          dispatch(setUniversity(data));
          dispatch(setUniversityId(data.univercity_details?.id));
          dispatch(setBannerArr(data.banner));
        }
      })
      .catch((err) => console.log(err));
  };

  const uniId = useSelector((state) => state.mainuniversity?.universityId);
  const mainUniversity = useSelector(
    (state) => state.mainuniversity?.university
  );

  const uniDetails = mainUniversity?.univercity_details;
  const uniCourses = mainUniversity?.programme;
  const facultyInfo = mainUniversity?.faculty;

  const bannerBox = useSelector((state) => state.mainuniversity?.bannerArr);
  const manSe = mainUniversity?.student_manse;
  const faq = mainUniversity?.faq;

  const dispatch = useDispatch();

  useEffect(() => {
    if (uniName) {
      getUniversityDetails();
    }
  }, [uniName]);

  const [readMore, setReadMore] = useState({
    universitySummary: false,
    onlineExpert: false,
  });

  const subheaderContent = [
    {
      title: "Overview",
      link: "#overview",
    },
    {
      title: "Offers",
      link: "#offers",
    },
    {
      title: "Specification",
      link: "#Specification",
    },
    {
      title: "Summary",
      link: "#summary",
    },
    {
      title: "Brochure",
      link: "#Brochure",
    },
    {
      title: "Compare",
      link: "#Compare",
    },
    {
      title: "Expert Review",
      link: "#Expert_Review",
    },
    {
      title: "faculty",
      link: "#faculty",
    },
    {
      title: " Student Mann Se",
      link: "#Student_Mann_Se",
    },
    {
      title: "  Latest Update",
      link: "#Latest_Update",
    },
    {
      title: "Blogs",
      link: "#Blogs",
    },
    {
      title: "Faq's",
      link: "#Faqs",
    },
    {
      title: "Expert Advice",
      link: "#Expert_Advice",
    },
    {
      title: "Contact",
      link: "#review_wrapper",
    },
  ];

  const specificationOpt = [
    {
      title: "Established",
      value:
        uniDetails?.established === "" || uniDetails?.established === "-"
          ? "-"
          : uniDetails?.established,
    },
    {
      title: "Approval",
      value:
        uniDetails?.approval === "" || uniDetails?.approval === "-"
          ? "-"
          : uniDetails?.approval,
    },
    {
      title: "Loan & EMI",
      value:
        uniDetails?.loan_emi === "" || uniDetails?.loan_emi === "-"
          ? "-"
          : uniDetails?.loan_emi,
    },
    {
      title: "NAAC & NIRF",
      value:
        uniDetails?.naac_nirf === "" || uniDetails?.naac_nirf === "-"
          ? "-"
          : uniDetails?.naac_nirf,
    },
    {
      title: "Placement",
      value:
        uniDetails?.placement === "" || uniDetails?.placement === "-"
          ? "-"
          : uniDetails?.placement,
    },
    {
      title: "UG Rating",
      value:
        uniDetails?.ug_rating === "" || uniDetails?.ug_rating === "-"
          ? "-"
          : uniDetails?.ug_rating,
    },
  ];
  const courseList = uniCourses?.map((item) => {
    return {
      coursename: item.name,
      specialization:
        item.specialization === "-" || item.specialization === ""
          ? ""
          : `${item.specialization}+Specializations`,
      totalfees: item.fees_amount,
      completefees:
        item.complete_fees === "" ? "" : `${baseUrl}${item.complete_fees}`,
    };
  });

  return (
    <CommonLayout>
      <CourseNameDetails
      key={uniqid()}
      rating={uniDetails?.rate}
        courseName={mainUniversity?.univercity_details?.name}
        reviewCount={mainUniversity?.univercity_details?.total_review}
      />
      <SubHeader subheaderContent={subheaderContent} />
      <JulyOfferWithCarousel
        key={uniqid()}
        // imgSrc={bannerImg && bannerImg}
        banner={bannerBox && bannerBox}
        details={uniDetails && uniDetails}
      />
      <div id="University_All" className={`${CrStyle.course} course`}>
        <div className="container">
          <div className="row">
            {/* Course */}
            <div className="col-lg-8">
              <div className={CrStyle.course_container}>
                <GetAssistanceBox
                key={uniqid()}
                  whatabout={`Contact ${uniDetails?.name} Now `}
                  subtext="Get in touch with a University Authorized Representative"
                  buttonName="Get Assistance"
                />
                <UgcAdd key={uniqid()} imgSrc={`${baseUrl}${uniDetails?.small_banner}`} />
                <CourseSpecification
                key={uniqid()}
                  boxValue={specificationOpt}
                  title="University Key Specification"
                />
                {/*-University VIEW MORE / LESS---*/}
                <UniversitySummary
                key={uniqid()}
                  mainId="summary"
                  mainclassName="univer_Summary mt-4"
                  title="University Summary "
                  defaultShow={uniDetails?.short_summary}
                  displayWhat={readMore.universitySummary}
                  readMoreBtnClick={() =>
                    setReadMore({
                      ...readMore,
                      universitySummary: !readMore.universitySummary,
                    })
                  }
                  details={uniDetails?.summary}
                />

                {/*-University VIEW MORE / LESS---*/}
                <GetAssistanceBox
                key={uniqid()}
                  whatabout="University Online Brochure"
                  subtext={`Download ${uniDetails?.name} in Just one click to view all course & Fee`}
                  buttonName="Download Brochure"
                  downloadFile={uniDetails?.online_broucher}
                  buttonclassName="col-lg-4"
                  textColclassName="col-lg-8"
                  mainclassName="mt-5"
                  id="Brochure"
                />
                <CourseFee
                key={uniqid()}
                  courseList={courseList}
                  heading="Program offer by university"
                />
                <div id={CrStyle.e_loan} className="mt-5 mb-3">
                  <Link
                    type="button"
                    data-toggle="modal"
                    data-target="#modalLoginForm2"
                    className=""
                  >
                    Need an Educational Loan Apply to get Instant Loan
                  </Link>
                </div>
                <UniversitySummary
                key={uniqid()}
                  mainId="Expert_Review"
                  mainclassName="uni_Expert_wrapper mt-5"
                  title="University Online Expert Review"
                  defaultShow={uniDetails?.short_online_expert_review}
                  displayWhat={readMore.onlineExpert}
                  readMoreBtnClick={() =>
                    setReadMore({
                      ...readMore,
                      onlineExpert: !readMore.onlineExpert,
                    })
                  }
                  details={uniDetails?.online_expert_review}
                />

                <FacultyDetails
                key={uniqid()}
                  text={uniDetails?.faculty}
                  uniLogo={`${baseUrl}${uniDetails?.logo}`}
                  facultyList={facultyInfo && facultyInfo}
                />
              </div>
            </div>
            {/* Course Sidebar */}
            <div className="col-lg-4">
              <CourseSideBar key={uniqid()} details={uniDetails && uniDetails} />
            </div>
          </div>
        </div>
      </div>
      <UniversityCourseCarousel
      key={uniqid()}
        courses={uniCourses && uniCourses}
        logo={`${baseUrl}${uniDetails?.logo}`}
      />
      <StudentManse2 key={uniqid()} details={manSe && manSe} />
      <LatestUpdates key={uniqid()} condition={uniId && uniId} />
      <Blogs key={uniqid()} uniId={uniId && uniId} />
      <TopUniversity />
      <Faqs key={uniqid()} details={faq && faq} uniname={uniDetails?.name} />
      <WriteAReview />
      <ExpertAdvice getModal="#modalLoginForm2" />
      <InNews />
      <NewsLetter />
    </CommonLayout>
  );
}
