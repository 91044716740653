import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCourseList: [],
  specializationList: [],
  socialList: {},
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setAllCourseList: (state, action) => {
      state.allCourseList = action.payload;
    },
    setSpecializationList: (state, action) => {
      state.specializationList = action.payload;
    },
    setSocialList: (state, action) => {
      state.socialList = action.payload;
    },
  },
});

export const { setAllCourseList, setSpecializationList, setSocialList } =
  headerSlice.actions;
export default headerSlice.reducer;
