import React, { useEffect, useState } from "react";
import CommonLayout from "../layouts/CommonLayout";
import Breadcumbs from "../components/BlogComponents/Breadcumbs";
import Style from "../assets/styles/ModuleStyle/course.module.css";
import BlogList from "../components/BlogComponents/BlogList";
import OpenModalCommon from "../components/Elements/OpenModalCommon";
import BlogSideBar from "../components/BlogComponents/BlogSideBar";
import ExpertAdvice from "../components/ExpertAdvice";
import NewsLetter from "../components/NewsLetter";
import { apiBaseUrl } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { setBlogListData } from "../reducer/BlogSlice";

export default function Blog() {
  const dispatch = useDispatch();
  const [recentPost, setRecentPost] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const blogList = useSelector((state) => state.blogs?.blogList);
  const fetchBlogList = () => {
    // setLoading(true);
    fetch(`${apiBaseUrl}blog-list`)
      .then((res) => res.json())
      .then((data) => {
        // setLoading(false);
        if (data.status === "success") {
          dispatch(setBlogListData(data && data.blog_list));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error(error);
      });
  };

  const fetchRecentPost = () => {
    fetch(`${apiBaseUrl}recent-post`)
      .then((res) => res.json())
      .then((data) => {
        // setLoading(false);
        if (data.status === "success") {
          setRecentPost([...data.recent_blog]);
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error(error);
      });
  };

  const fetchCategory = () => {
    fetch(`${apiBaseUrl}blog-category-list`)
      .then((res) => res.json())
      .then((data) => {
        // setLoading(false);
        if (data.status === "success") {
          setCategoryList([...data.blog_category_list]);
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchBlogList();
    fetchRecentPost();
    fetchCategory();
  }, []);

  return (
    <CommonLayout>
      <Breadcumbs />
      <div className={`${Style.blog} blog`}>
        <div className="container" data-aos="fade-in">
          <div className="row">
            <div className="col-md-9">
              <BlogList data={blogList && blogList} />
              {/*---- Get Advice from Expert !-----*/}
              <OpenModalCommon
                text="Confused ? Get Advice from Expert !"
                modalId="#modalLoginForm"
              />
            </div>
            {/* Blog Sidebar */}
            <div className="col-lg-3">
              <BlogSideBar
                recentPost={recentPost && recentPost}
                categoryList={categoryList && categoryList}
              />
            </div>
            {/* Blog Sidebar END */}
          </div>
        </div>
      </div>
      <ExpertAdvice getModal="#modalLoginForm" />
      <NewsLetter />
    </CommonLayout>
  );
}
