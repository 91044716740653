import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../config";
function UniversityList({ data }) {
  const navigate = useNavigate();
  const universities = data.map((item) => ({
    universityId:item.universities_id,
    name: item.univercity_name,
    trend: item.student_trend,
    fees: item.fees,
    Approvals: item.approval, // You can include other fields as needed
    gyanScore: item.gyan_score,
    modeoflearning: item.education_model,
    Pros: item.pros,
    img: item.image,
    videourl: item.videourl,
    audiourl: item.audio_url,
    sampleCertificate: item.certificate,
    details: item.short_summary,
    readMoreUrl: item.readMoreUrl,
  }));

  return (
    <div className="best_college_wrap">
      <div className="container">
        {data &&
          data?.map((item, index) => (
            <div key={index} className={`row ${Style.best_college_full}`}>
              <div className="col-md-3">
                <div className="img-details">
                  <div className={`${Style["trends-title"]} mb-3`}>
                    Satisfied Student Trend: {item.student_trend}
                  </div>
                  <div className={Style["trends-img"]}>
                    <img className="img-fluid" src={`${baseUrl}${item.image}`} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className={`${Style["ol-detail-box"]} mt-4 mt-md-0`}>
                  <h3 className>{item.univercity_name}</h3>
                  <div className="starr">
                    <div className={`${Style.rating_r} ${Style.rating_r_4}`}>
                    <div className="stars-common-outer">
                        <div
                          className="stars-common-inner"
                          style={{ width: `${(Number(item.ug_rating) / 5) * 100}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <ul className={Style["university-details"]}>
                    <li>
                      <strong>₹ Fee:</strong> ₹{item.fees} per semester onwards
                    </li>
                    <li>
                      <strong>Approvals:</strong> {item.approval}
                    </li>
                    <li>
                      <strong>University Gyan Score:</strong> {item.gyan_score}
                    </li>
                    <li>
                      <strong>Mode of Learning:</strong> {item.education_model}
                    </li>
                    <li>
                      <span className="color-green">
                        <strong>Pros:</strong>
                      </span>{" "}
                      {item.pros}
                    </li>
                  </ul>
                  <div className="d-flex flex-wrap justify-content-start playerss pt-3">
                    <div className>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={Style.watch_video}
                        to={item.video_url}
                      >
                        {" "}
                        Watch Video <i className="uil uil-play-circle" />
                      </Link>
                    </div>
                    <div className>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={Style.watch_video}
                        target="_BLANK"
                        to={item.audio_url}
                      >
                        {" "}
                        Listen Audio <i className="uil uil-record-audio" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2 mt-5 mt-md-0">
                <div className="sample_certificate-box">
                  <button className="btn btn-primary btn-large compare-btn p-2" onClick={()=>navigate(`/comparison/${item.universities_id}`)}>
                    <i className="uil uil-create-dashboard" /> Add To Compare{" "}
                  </button>
                </div>
                <div className="mt-3 samp-certificate">
                  <Link
                    to
                    data-toggle="modal"
                    data-target="#modalLoginImg"
                    className="gyan-link popup-zoom"
                  >
                    <img
                      className="img-fluid"
                      src={`${baseUrl}${item.certificate}`}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="bstcollege_summary mt-5 col-md-12">
                <div className={Style.college_summary}>{item.short_summary}</div>
                <Link
                  className={`${Style["color-blue"]} ${Style["top-uni-more"]}`}
                  to={`/university/${item.univercity_name}`}
                >
                  Read more
                </Link>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default UniversityList;
