import React, { useState } from 'react';
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import ReCAPTCHA from "react-google-recaptcha";

export default function WriteAReview() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    course: '',
    message: ''
  });
  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptcha = (value) => {
    setIsVerified(!!value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isVerified) {
      alert("Please verify the CAPTCHA to proceed.");
      return;
    }
    console.log("Form Data Submitted: ", formData);
    // Submit formData here
  };

  return (
    <div id="review_wrapper" className="review_wrapper section bg-light">
      <div className="container" data-aos="fade-up">
        <div className="row">
          <div className="col pb-4">
            <div className="section_title text-left">
              <h2 className="section_title"> Write A Review</h2>
            </div>
          </div>
        </div>
        <div className="row pt-3 pt-md-4">
          <div className={CrStyle.review_form_col}>
            <form onSubmit={handleSubmit} className={CrStyle.review_form}>
              <div className="row">
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Name</div>
                  <input
                    name="name"
                    type="text"
                    className={`${CrStyle["form-control"]} form-control`}
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Email</div>
                  <input
                    name="email"
                    type="email"
                    className={`${CrStyle["form-control"]} form-control`}
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Mobile</div>
                  <input
                    name="mobile"
                    type="tel"
                    className={`${CrStyle["form-control"]} form-control`}
                    required
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6 form-group mt-lg-0 mt-3">
                  <div className={`${CrStyle.form_title} form_title`}>Select Course</div>
                  <select
                    name="course"
                    className={`${CrStyle["form-control"]} form-control`}
                    value={formData.course}
                    onChange={handleChange}
                  >
                    <option value="">Choose Course</option>
                    <option value="Course1">Course 1</option>
                    <option value="Course2">Course 2</option>
                    <option value="Course3">Course 3</option>
                  </select>
                </div>

                <div className="col-lg-12 form-group">
                  <div className={`${CrStyle.form_title} form_title`}>Message</div>
                  <textarea
                    name="message"
                    rows={5}
                    className={`${CrStyle["form-control"]} form-control`}
                    required
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <div className="py-2 text-start d-block">
                  <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={handleRecaptcha} />
                </div>
                <div>
                  <button type="submit" className="comment_button trans_200">
                    submit now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
