import React from "react";
import { Link } from "react-router-dom";

export default function TopSpecialization({ data }) {
  return (
    <div
      id="Top_specialization"
      className="section general_width spec_top-special bg-white"
    >
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col-12 col-md-12 mx-auto col">
            <div
              name="top_specialisation"
              style={{ paddingTop: 70 }}
              className="row"
            >
              <h3 className="fw-bold mb-3 tab_heading">
                Top Specializations for Online MBA in 2024
              </h3>
              <div className="mb-5 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row">
                {Array.isArray(data) &&
                  data?.map((item, i) => (
                    <div className="mb-3 col" key={i}>
                      <div className="border px-3 py-3 rounded h-100 position-relative d-flex align-items-center">
                        <Link
                          className="text-dark fs-14 d-flex align-items-center justify-content-between d-block pe-3"
                          role="button"
                          to="#"
                        >
                          {item.top_specialization_text}
                          <i className="uil uil-external-link-alt fs-4 text-primary position-absolute end-0 me-2" />
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
