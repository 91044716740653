import React, { useEffect, useState } from "react";
import CommonLayout from "../layouts/CommonLayout";
import CrStyle from "../assets/styles/ModuleStyle/course.module.css";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import { useNavigate, useParams } from "react-router-dom";
import { apiBaseUrl, baseUrl } from "../config";
import { useDispatch, useSelector } from "react-redux";
import { setCategoryDetails } from "../reducer/expoloreCategorySlice";
import Parser from "../components/Elements/Parser";

export default function CourseCategoryExplore() {
  const { name } = useParams();
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState("exp-course-all");
  const dispatch = useDispatch();
  const exploredetails = useSelector((state) => state.explore?.exploreDetails);
  const allOption = exploredetails?.general_course_list;
  const courseList = exploredetails?.details;

  const fetchAll = async () => {
    fetch(`${apiBaseUrl}explore-course-category/${name}`).then((res) =>
      res
        .json()
        .then((data) => {
          if (data) {
            dispatch(setCategoryDetails(data));
          }
        })
        .catch((err) => console.log(err))
    );
  };

  useEffect(() => {
    if (name) {
      fetchAll();
    }
  }, [name]);

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  return (
    <CommonLayout>
      <div className={`${CrStyle["explore-category"]} mt-3 mt-lg-3`}>
        <div className="container" data-aos="fade-in">
          <div className="row py-4">
            <div className="col-md-7 mb-3">
              {/* <h5>Dream Big, Learn Anywhere: Conquer Your Degree Online</h5> */}
              <div className={`${CrStyle.university_title} text-info my-3`}>
                {exploredetails?.main_course_name}
              </div>
              {/* <p className="fs-16"> */}
              <Parser content={exploredetails?.main_course_desc} />
              {/* </p> */}
            </div>
            <div className="col-md-5">
              <div className="explore-head-img gap-2">
                <img
                  className="img-fluid"
                  // src="/images/explore-online.jpg"
                  src={`${baseUrl}${exploredetails?.main_course_image}`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="explore-wrapper bg-light section">
        <div className="container" data-aos="fade-in">
          <div className="row py-2 py-lg-5">
            <div className="col-lg-12">
              <div className="filtering">
                <div className={`${CrStyle["filter-cat"]} row`}>
                  <div className="col col-md-5 col-xs-6">
                    <div className="title mb-3 fs-4 fw-bold">
                      <span className={`${CrStyle.blink} blink`}>
                        <i className="uil uil-graduation-cap text-info fs-2" />
                      </span>
                      Select {exploredetails?.main_course_name}
                    </div>
                    <select
                      className="form-control border-3"
                      value={selectedCourse}
                      onChange={handleCourseChange}
                    >
                      <option value="exp-course-all">Select Course</option>
                      {allOption?.map((item, index) => (
                        <option value={item.id}>{item.course_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className={`row ${CrStyle["filter-cat-results"]}`}>
                  {courseList
                    ?.filter(
                      (course) =>
                        selectedCourse === "exp-course-all" ||
                        String(course.general_course_id) === selectedCourse
                    )
                    .map((course, index) => (
                      <div
                        key={index}
                        className={`${CrStyle["e-course"]} ${CrStyle.active} col col-md-4 col-xs-6 my-3`}
                        data-cat={course.general_course_id}
                      >
                        <div
                          className={`${CrStyle["exp-cat-col"]} border-radius`}
                        >
                          <div className="exp-img">
                            <img
                              className="img-fluid"
                              src={`${baseUrl}${course.course_image}`}
                              alt=""
                            />
                          </div>
                          <div className="exp-details text-left px-3 py-2">
                            <h4 className="exp-university_title fs-13">
                              {course.univercity_name}
                            </h4>
                            <p className="exp-course-title mb-2">
                              {course.course_name}
                            </p>
                            <h4 className="text-info">{course.fees}</h4>
                          </div>
                          <button
                            onClick={() =>
                              navigate(
                                `/course/${course.univercity_name}/${course.course_name}`
                              )
                            }
                            className="btn btn-primary btn-large w-100 border-radius-0"
                          >
                            Show Complete Details
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ExpertAdvice />
      <InNews />
      <NewsLetter />
    </CommonLayout>
  );
}
