import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css"
import Parser from "../Elements/Parser";

export default function AdmissionProcedure({ data }) {
  return (
    <div
      id="Admission_procedure"
      className={`section ${Style.general_width} spec_admission bg-blue`}
    >
      <div className="container" data-aos="fade-in">
          <div className="row">
            <div className="col-11 col-md-10 mx-auto col">
              <div
                name="admission_procedure"
                style={{ paddingTop: 70 }}
                className="row text-white"
              >
                <Parser content={data && data?.admin_procedure_description} />

                <div className="mt-4 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 mx-auto row">

                  <div className="mb-3 col">
                    <div
                      className="px-3 py-5 h-100"
                      style={{
                        borderRadius: 16,
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        backgroundColor: "#003075",
                      }}
                    >
                      <i className="uil uil-check-circle fs-1 text-white text-center" />
                      <p className="text-white mb-2 fw-bold">
                        <Parser content={data && data?.admin_procedure_heading1} />
                      </p>
                      <p className="text-white fs-14">
                        <Parser content={data && data?.admin_procedure_content1} />
                      </p>
                    </div>
                  </div>
                  <div className="mb-3 col">
                    <div
                      className="px-3 py-5 h-100"
                      style={{
                        borderRadius: 16,
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <i className="uil uil-check-circle fs-1 text-white text-center" />
                      <p className="text-white mb-2 fw-bold">
                        <Parser content={data && data?.admin_procedure_heading2} />
                      </p>
                      <p className="text-white fs-14">
                        <Parser content={data && data?.admin_procedure_content2} />
                      </p>
                    </div>
                  </div>
                  <div className="mb-3 col">
                    <div
                      className="px-3 py-5 h-100"
                      style={{
                        borderRadius: 16,
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <i className="uil uil-check-circle fs-1 text-white text-center" />
                      <p className="text-white mb-2 fw-bold">
                        <Parser content={data && data?.admin_procedure_heading3} />
                      </p>
                      <p className="text-white fs-14">
                        <Parser content={data && data?.admin_procedure_content3} />
                      </p>
                    </div>
                  </div>
                  <div className="mb-3 col">
                    <div
                      className="px-3 py-5 h-100"
                      style={{
                        borderRadius: 16,
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        backgroundColor: "rgba(255, 255, 255, 0.1)",
                      }}
                    >
                      <i className="uil uil-check-circle fs-1 text-white text-center" />
                      <p className="text-white mb-2 fw-bold">
                        <Parser content={data && data?.admin_procedure_heading4} />
                      </p>
                      <p className="text-white fs-14">
                        <Parser content={data && data?.admin_procedure_content4} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       

      </div>
    </div>
  );
}
