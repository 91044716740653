import React from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import OwlCarousel from "react-owl-carousel";

import owlStyle from "../../assets/styles/ModuleStyle/customOwl.module.css";

export default function FacultyList({ uniLogo, facultyList }) {
  return (
    <>
      <div
        className="faculty_lists mt-5"
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <OwlCarousel
          id="faculty_university"
          className="owl-carousel owl-theme row"
          nav
          dots={false}
          stageOuterClass={owlStyle.owlStageOuterSpec}
          navText={[
            "<i class='fa fa-chevron-left'></i>",
            "<i class='fa fa-chevron-right'></i>",
          ]}
          navContainerClass={owlStyle.containerNav}
          navClass={["navPrev", "navNext"]}
          responsive={{
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 3,
            },
          }}
        >
          {facultyList?.map((item, index) => (
            <div className="item col" data-aos="fade-in" data-aos-delay={100}>
              <div className={CrStyle["faculty-box"]}>
                <div className={CrStyle["slide-img"]}>
                  <h4 className={CrStyle.faculty}>{item.name.split("-")[0]}</h4>
                  <img
                    className={CrStyle["faculty-bg"]}
                    src={uniLogo && uniLogo}
                    alt=""
                  />
                </div>
                <div className={CrStyle["detail-box"]}>
                  <div className={CrStyle.type}>
                    <span className={CrStyle["university-name"]}>
                    {item.name.split("-")[1]}
                      <br />
                      {item.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="item col" data-aos="fade-in" data-aos-delay={100}>
            <div className={CrStyle["faculty-box"]}>
              <div className={CrStyle["slide-img"]}>
                <h4 className={CrStyle.faculty}>Dr.P.Ranjana </h4>
                <img
                  className={CrStyle["faculty-bg"]}
                  src="/images/Hindustan-university.png"
                  alt=""
                />
              </div>
              <div className={CrStyle["detail-box"]}>
                <div className={CrStyle.type}>
                  <span className={CrStyle["university-name"]}>
                    Professor &amp; HOD (MCA)
                    <br />
                    In 1999, Dr.P.Ranjana began her employment with the
                    Hindustan Institute of Technology and Science. She currently
                    serves as the department's head and a professor of computer
                    applications.{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="item col" data-aos="fade-in" data-aos-delay={100}>
            <div className={CrStyle["faculty-box"]}>
              <div className={CrStyle["slide-img"]}>
                <h4 className={CrStyle.faculty}>Dr.P.Ranjana </h4>
                <img
                  className={CrStyle["faculty-bg"]}
                  src="/images/Hindustan-university.png"
                  alt=""
                />
              </div>
              <div className={CrStyle["detail-box"]}>
                <div className={CrStyle.type}>
                  <span className={CrStyle["university-name"]}>
                    Professor &amp; HOD (MCA)
                    <br />
                    In 1999, Dr.P.Ranjana began her employment with the
                    Hindustan Institute of Technology and Science. She currently
                    serves as the department's head and a professor of computer
                    applications.{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="item col" data-aos="fade-in" data-aos-delay={100}>
            <div className={CrStyle["faculty-box"]}>
              <div className={CrStyle["slide-img"]}>
                <h4 className={CrStyle.faculty}>Dr.P.Ranjana </h4>
                <img
                  className={CrStyle["faculty-bg"]}
                  src="/images/Hindustan-university.png"
                  alt=""
                />
              </div>
              <div className={CrStyle["detail-box"]}>
                <div className={CrStyle.type}>
                  <span className={CrStyle["university-name"]}>
                    Professor &amp; HOD (MCA)
                    <br />
                    In 1999, Dr.P.Ranjana began her employment with the
                    Hindustan Institute of Technology and Science. She currently
                    serves as the department's head and a professor of computer
                    applications.{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="item col" data-aos="fade-in" data-aos-delay={100}>
            <div className={CrStyle["faculty-box"]}>
              <div className={CrStyle["slide-img"]}>
                <h4 className={CrStyle.faculty}>Dr.P.Ranjana </h4>
                <img
                  className={CrStyle["faculty-bg"]}
                  src="/images/Hindustan-university.png"
                  alt=""
                />
              </div>
              <div className={CrStyle["detail-box"]}>
                <div className={CrStyle.type}>
                  <span className={CrStyle["university-name"]}>
                    Professor &amp; HOD (MCA)
                    <br />
                    In 1999, Dr.P.Ranjana began her employment with the
                    Hindustan Institute of Technology and Science. She currently
                    serves as the department's head and a professor of computer
                    applications.{" "}
                  </span>
                </div>
              </div>
            </div>
          </div> */}
        </OwlCarousel>
      </div>
    </>
  );
}
