import React, { useState } from "react";
import ReadMoreBtn from "../Elements/ReadMoreBtn";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import parser from "html-react-parser";

const UniversitySummary = ({
  details,
  defaultShow,
  // readMoreBtnClick,
  // displayWhat,
  mainId,
  mainClass,
  title,
  isButton = true,
}) => {
  const [show, setShow] = useState(false);
  function readMoreBtnClick() {
    setShow(!show);
  }
  return (
    <div id={mainId} className={mainClass}>
      <div className>
        <div className="row mb-2">
          <h3>{title}</h3>
        </div>
        <div className={CrStyle.summary_more}>
          <div className={`${CrStyle["summary-show"]} summary-show row`}>
            <p dangerouslySetInnerHTML={{ __html: defaultShow }}></p>
          </div>
          <div
            className={`${CrStyle["read-more-content"]} ${CrStyle["summary-show"]}  row`}
            style={{
              display: show === true ? "block" : "none",
            }}
          >
            {details && typeof details === "string" ? parser(details) : null}
          </div>
          {isButton === true && (
            <div className="d-flex justify-content-end">
              <ReadMoreBtn onClick={readMoreBtnClick} condition={show} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(UniversitySummary);
