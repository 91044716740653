import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css"

export default function Breadcumbs() {
  return (
    <div className={`${Style.university}  ${Style.Blogs_title}`}>
      <div className={`${Style["breadcrumbs_container"]} compair_crumbs`}>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className={Style.university_title}>Blog</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
