import React from 'react'
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import owlStyle from "../../assets/styles/ModuleStyle/customOwl.module.css";

import OwlCarousel from "react-owl-carousel"

export default function SpecializationOffered() {
  return (
    <div
    id="Specialization_Offered"
    className="uni_special_course"
  >
    <div className="title mt-5">
      <h3>Specialization Offered</h3>
    </div>
    <div className="Specialization-list mt-lg-4 mt-3">
      <OwlCarousel
        id="course_specializ"
        className="owl-carousel owl-theme row"
        nav
        dots={false}
        stageOuterClass={owlStyle.owlStageOuterSpec}
        navText={[
          "<i class='fa fa-chevron-left'></i>",
          "<i class='fa fa-chevron-right'></i>",
        ]}
        navContainerClass={owlStyle.containerNav}
        navClass={["navPrev", "navNext"]}
      >
        {/*--course_specializ-----*/}
        <div
          className="item col"
          data-aos="fade-in"
          data-aos-delay={100}
        >
          <div className={CrStyle["specializ-box"]}>
            <div className={CrStyle["specializ-img"]}>
              <img
                className
                src="/images/specialization/block-chain-500-220.jpg"
                alt=""
              />
            </div>
            <div className={CrStyle["spec-tital-box"]}>
              <div className={CrStyle.title}>
                <a href>
                  Block Chain Technology Chain Technology{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*--course_specializ-----*/}
        <div
          className="item col"
          data-aos="fade-in"
          data-aos-delay={100}
        >
          <div className={CrStyle["specializ-box"]}>
            <div className={CrStyle["specializ-img"]}>
              <img
                className
                src="/images/specialization/block-chain-500-220.jpg"
                alt=""
              />
            </div>
            <div className={CrStyle["spec-tital-box"]}>
              <div className={CrStyle.title}>
                <a href>
                  Block Chain Technology Chain Technology{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*--course_specializ-----*/}
        <div
          className="item col"
          data-aos="fade-in"
          data-aos-delay={100}
        >
          <div className={CrStyle["specializ-box"]}>
            <div className={CrStyle["specializ-img"]}>
              <img
                className
                src="/images/specialization/block-chain-500-220.jpg"
                alt=""
              />
            </div>
            <div className={CrStyle["spec-tital-box"]}>
              <div className={CrStyle.title}>
                <a href>
                  Block Chain Technology Chain Technology{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*--course_specializ-----*/}
      </OwlCarousel>
    </div>
  </div>
  )
}
