import React, { useEffect } from "react";
import CommonLayout from "../layouts/CommonLayout";
import CrStyle from "../assets/styles/ModuleStyle/course.module.css";
import { useParams } from "react-router-dom";
import LatestUpdates from "../components/LatestUpdates";
import Blogs from "../components/Blogs";
import TopUniversity from "../components/TopUniversity";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import CourseNameDetails from "../components/CourseComponents/CourseNameDetails";
import SubHeader from "../components/CourseComponents/SubHeader";
import JulyOfferWithCarousel from "../components/CourseComponents/JulyOfferWithCarousel";
import GetAssistanceBox from "../components/CourseComponents/GetAssistanceBox";
import UgcAdd from "../components/CourseComponents/UgcAdd";
import CourseSpecification from "../components/CourseComponents/CourseSpecification";
import UniversitySummary from "../components/CourseComponents/UniversitySummary";
import CourseFee from "../components/CourseComponents/CourseFee";
import SpecializationOffered from "../components/CourseComponents/SpecializationOffered";
import FacultyDetails from "../components/CourseComponents/FacultyDetails";
import CourseSideBar from "../components/CourseComponents/CourseSideBar";
import Faqs from "../components/CourseComponents/Faqs";
import WriteAReview from "../components/CourseComponents/WriteAReview";
import StudentManse2 from "../components/CourseComponents/StudentManse2";
import { useDispatch, useSelector } from "react-redux";
import { apiBaseUrl, baseUrl } from "../config";
import { setCourse } from "../reducer/courseSlice";
import {
  setBannerArr,
  setUniversity,
  setUniversityId,
} from "../reducer/universitySlice";
import uniqid from "uniqid";

export default function Course() {
  // const location = useLocation();
  const dispatch = useDispatch();
  const universityId = useSelector(
    (state) => state.mainuniversity?.universityId
  );
  const uniId = useSelector((state) => state.mainuniversity?.universityId);

  const mainUniversity = useSelector(
    (state) => state.mainuniversity?.university
  );
  const uniDetails = mainUniversity?.univercity_details;

  const courseData = useSelector((state) => state.course?.courseInfo);

  const coursedetails = courseData && courseData.course_details;
  const coursespecialization = courseData?.specialization;
  const facultyInfo = mainUniversity?.faculty;
  const manSe = courseData?.student_manse;
  const faq = courseData?.faq;
  const bannerBox = useSelector((state) => state.mainuniversity?.bannerArr);

  const { uniName, courseName } = useParams();

  // const [readMore, setReadMore] = useState({
  //   universitySummary: false,
  //   onlineExpert: false,
  // });

  // useEffect(() => {
  //   if (bannerImg) {
  //     setBannerList(bannerImg.map((item) => `${baseUrl}${item.image}`));
  //   }
  // }, [bannerImg]);

  const fetchCourse = async () => {
    try {
      if (courseName && universityId) {
        const res = await fetch(
          `${apiBaseUrl}course-details/${universityId}/${courseName}`
        );
        const data = await res.json();
        if (data.status === "success") {
          dispatch(setCourse({ ...data }));
          dispatch(setBannerArr(data?.banner));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (courseName && universityId) {
      fetchCourse();
    }
  }, [courseName, universityId]);
  const getUniversityDetails = async () => {
    await fetch(`${apiBaseUrl}univercity-details/${uniName}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          dispatch(setUniversity(data));
          dispatch(setUniversityId(data?.univercity_details?.id));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (uniName) {
      getUniversityDetails();
    }
  }, [uniName]);

  const courseList = [
    {
      coursename: coursedetails?.name,
      specialization:
        coursedetails?.specialization === "-" ||
        coursedetails?.specialization === ""
          ? ""
          : ` ${coursedetails?.specialization}+ Specialization`,
      totalfees: coursedetails?.fees_amount,
      completefees:
        coursedetails?.complete_fees === ""
          ? ""
          : `${baseUrl}${coursedetails?.complete_fees}`,
    },
  ];

  const subheaderContent = [
    {
      title: "Overview",
      link: "#overview",
    },
    {
      title: "Offers",
      link: "#offers",
    },
    {
      title: "Specification",
      link: "#Specification",
    },
    {
      title: "Summary",
      link: "#summary",
    },
    {
      title: "Brochure",
      link: "#Brochure",
    },
    {
      title: "Compare",
      link: "#Compare",
    },
    {
      title: "Expert Review",
      link: "#Expert_Review",
    },
    {
      title: "faculty",
      link: "#faculty",
    },
    {
      title: " Student Mann Se",
      link: "#Student_Mann_Se",
    },
    {
      title: "  Latest Update",
      link: "#Latest_Update",
    },
    {
      title: "Blogs",
      link: "#Blogs",
    },
    {
      title: "Faq's",
      link: "#Faqs",
    },
    {
      title: "Expert Advice",
      link: "#Expert_Advice",
    },
    {
      title: "Contact",
      link: "#review_wrapper",
    },
  ];

  const specificationOpt = [
    {
      title: "Specialization",
      value:
        coursedetails?.specialization === "" ||
        coursedetails?.specialization === "-"
          ? "-"
          : coursedetails?.specialization,
    },
    {
      title: "Placement Assistance",
      value: coursedetails?.placement,
    },
    {
      title: "Education Model",
      value: coursedetails?.education_model,
    },
    {
      title: "Loan and EMI",
      value: coursedetails?.loan_emi,
    },
    {
      title: "Alumni Status",
      value:
        coursedetails?.alumni_status === "" ||
        coursedetails?.alumni_status === "-"
          ? "-"
          : coursedetails?.alumni_status,
    },
    {
      title: "UG Rating",
      value: coursedetails?.ug_rating,
    },
  ];
  return (
    <CommonLayout>
      <>
        <CourseNameDetails
        key={uniqid()}
        rating={coursedetails?.rate}
          courseName={coursedetails?.name}
          universityName={`${uniDetails?.name} -`}
          reviewCount={coursedetails?.total_review}
        />
        <SubHeader subheaderContent={subheaderContent} />
        <JulyOfferWithCarousel
        key={uniqid()}
          banner={bannerBox && bannerBox}
          details={coursedetails && coursedetails}
        />
        {/* Course */}
        <div id="Courses_All" className={`${CrStyle.course} course`}>
          <div className="container">
            <div className="row">
              {/* Course */}
              <div className="col-lg-8">
                <div className={CrStyle.course_container}>
                  <GetAssistanceBox
                  key={uniqid()}
                    whatabout={`Contact ${uniDetails?.name} Now `}
                    subtext="Get in touch with a University Authorized Representative"
                    buttonName="Get Assistance"
                  />
                  <UgcAdd key={uniqid()} imgSrc={`${baseUrl}${uniDetails?.small_banner}`} />
                  <CourseSpecification
                  key={uniqid()}
                    boxValue={specificationOpt}
                    title={`${coursedetails?.name}
        Specification`}
                  />
                  {/*-University VIEW MORE / LESS---*/}
                  <UniversitySummary
                  key={uniqid()}
                    mainId="summary"
                    mainclassName="univer_Summary mt-4"
                    title="Course Summary "
                    defaultShow={coursedetails?.short_summary}
                    // displayWhat={readMore.universitySummary}
                    // readMoreBtnClick={() =>
                    //   setReadMore({
                    //     ...readMore,
                    //     universitySummary: !readMore.universitySummary,
                    //   })
                    // }
                    details={coursedetails?.summary}
                  />

                  {/*-University VIEW MORE / LESS---*/}

                  <GetAssistanceBox
                  key={uniqid()}
                    whatabout="University Online Brochure"
                    subtext={`Download ${coursedetails?.name} in Just one click to view all course & Fee`}
                    buttonName="Download Brochure"
                    downloadFile={coursedetails?.online_broucher}
                    buttonclassName="col-lg-4"
                    textColclassName="col-lg-8"
                    mainclassName="mt-5"
                    id="Brochure"
                  />

                  <CourseFee key={uniqid()} courseList={courseList} />

                  {coursespecialization && coursespecialization?.length > 0 && (
                    <SpecializationOffered />
                  )}

                  <UniversitySummary
                  key={uniqid()}
                    mainId="Expert_Review"
                    mainclassName="uni_Expert_wrapper mt-5"
                    title="Course Online Expert Review"
                    defaultShow={coursedetails?.short_online_expert_review}
                    details={coursedetails?.online_expert_review}
                    // displayWhat={readMore.onlineExpert}
                    // readMoreBtnClick={() =>
                    //   setReadMore({
                    //     ...readMore,
                    //     onlineExpert: !readMore.onlineExpert,
                    //   })
                    // }
                  />

                  <FacultyDetails
                  key={uniqid()}
                    text={coursedetails?.faculty}
                    uniLogo={`${baseUrl}${uniDetails?.logo}`}
                    facultyList={facultyInfo && facultyInfo}
                  />
                </div>
              </div>
              {/* Course Sidebar */}
              <div className="col-lg-4">
                <CourseSideBar key={uniqid()} details={uniDetails && uniDetails} />
              </div>
            </div>
          </div>
        </div>
        {/*--------8 *4 Section END---------*/}
        {/*----------FULL Width Section Start----------*/}
        {/*--------Student Mann Se--------*/}
        <StudentManse2 key={uniqid()} details={manSe && manSe} />
        {/*-Latest Update--*/}
        <LatestUpdates key={uniqid()} condition={uniId && uniId} />
        {/* Blogs */}
        <Blogs key={uniqid()} uniId={uniId && uniId} />
        {/*--- Top Universities in India -----*/}
        <TopUniversity />
        {/*--- Top Universities in India -----*/}
        {/*--- FAQ's about Hindustan University -----*/}
        <Faqs key={uniqid()} details={faq && faq} uniname={coursedetails?.name} />
        {/*--- Review Section University -----*/}
        <WriteAReview />
        {/*---GET  Testimonials  ---*/}
        <ExpertAdvice getModal="#modalLoginForm2" />
        <InNews />
        <NewsLetter />
      </>
    </CommonLayout>
  );
}
