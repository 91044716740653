import React from "react";
import Style from '../../assets/styles/ModuleStyle/course.module.css'
import { Link } from "react-router-dom";
import Parser from "../Elements/Parser";

export default function ProgramFee({ data }) {
  return (
    <div
      id="program_fee"
      className={`section ${Style.general_width} spec_program-fees bg-light`}
    >
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col-12 col-xl-12 pt-4 col">
            <h3 className="fw-bold mb-4">
              Program Fees for Online MBA
            </h3>
            <div
              className="d-flex justify-content-between align-items-center px-3 py-4"
              style={{
                backgroundImage: "linear-gradient(100deg, #003075, #255ef5)",
                borderRadius: 16,
              }}
            >
              <div className="price-info text-white">
                <p className="h3">Starting at ₹ {data.starting_at}</p>
                <p className="fs-16 text-white">
                  Program Fee: {data.programme_fees}
                </p>
              </div>
              <div className="d-none d-lg-block">
                <span role="button">
                  <Link
                    to="#general-from-wrapper"
                    className="bg-white px-3 py-2 rounded shadow"
                  >
                    Apply Now
                  </Link>
                </span>
              </div>
            </div>
            <div className="mt-4 bg-white shadow-1 rounded mb-4">
              <div className="py-3 px-3 border rounded mb-3 bg-white  ">
                <Parser content={data && data.program_fees} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
