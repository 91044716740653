import React from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";
import { baseUrl } from "../../config";

export default function GetAssistanceBox({
  id,
  mainClass,
  textColClass = "col-lg-9",
  whatabout,
  subtext,
  buttonName,
  downloadFile,
  buttonClass = "col-lg-3",
}) {
  return (
    <div id={id} className={`${CrStyle.contact_university_popup} ${mainClass}`}>
      <div className="row d-flex flex-lg-row flex-column align-items-lg-center">
        <div className={`contact-uni_info ${textColClass}`}>
          <h3>{whatabout}</h3>
          <p> {subtext}</p>
        </div>
        <div
          className={`${CrStyle["contact-uni_btn"]} contact-uni_btn ${buttonClass}`}
        >
          {buttonName === "Download Brochure" ? (
            <Link
              className="btn btn-primary"
              style={{ color: "#fff" }}
              to={`${baseUrl}${downloadFile && downloadFile}`}
              target="_blank"
              download
            >
              {buttonName}
            </Link>
          ) : (
            <Link
              className="btn btn-primary"
              style={{ color: "#fff" }}
              data-toggle="modal"
              data-target="#modalLoginForm2"
              download
            >
              {buttonName}
            </Link>
          )}
         
        </div>
      </div>
    </div>
  );
}
