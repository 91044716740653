import React, { useEffect, useState } from "react";
import ReadMoreBtn from "./Elements/ReadMoreBtn";
import { apiBaseUrl, baseUrl } from "../config";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAllUniversityList } from "../reducer/allUniversitySlice";

function AllUniversityLogo() {
  const dispatch=useDispatch()
  const [showAll, setShowAll] = useState(false);
  const [allUniversity, setAllUniversity] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchAllUniversity = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}all-univercity`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          setAllUniversity(data);
          dispatch(setAllUniversityList(data?.univercity_list))
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error("Error fetching banner image:", error);
      });
  };
  const itemsToShow = showAll
    ? allUniversity?.univercity_list && allUniversity.univercity_list
    : allUniversity?.univercity_list &&
      allUniversity.univercity_list.slice(0, 12);
  useEffect(() => {
    fetchAllUniversity();
  }, []);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <div
      id="all-university-menu"
      className="all-university-menu pb-4 section-bg"
    >
      <div className="container" data-aos="fade-in">
        <div className="row" data-aos="fade-up" data-aos-delay="100">
          <div className="col pb-3">
            <div className="section_title text-left">
              <h2 className="section_title">All Universities</h2>
            </div>
          </div>
        </div>

        {loading ? (
          <h3>Loading...</h3>
        ) : (
          <>
            <div
              className="university-logos justify-content-start row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 mt-4"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              {itemsToShow &&
                itemsToShow.map((item, index) => (
                  <div
                    key={item.name + index}
                    className="col"
                    data-aos="fade-in"
                    data-aos-delay="100"
                  >
                    <Link href="#" className="single-item">
                      <img src={`${baseUrl}${item.logo}`} alt={item.name} />
                      <p>{item.name}</p>
                    </Link>
                  </div>
                ))}
            </div>

            <ReadMoreBtn
              className="read-more"
              onClick={toggleShowAll}
              condition={showAll}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default React.memo(AllUniversityLogo);
