import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function NewsLetter() {
  const number = useSelector(
    (state) => state.header?.socialList.get_started_number
  );

  return (
    <>
      <div className="newsletter d-flex flex-lg-row flex-column align-items-center">
        <div className="container" data-aos="fade-up" data-aos-delay={100}>
          <div className="row">
            <div className="col">
              <div className="newsletter_container d-flex flex-lg-row flex-column align-items-center">
                {/* Newsletter Content */}
                <div className="newsletter_content text-lg-left text-center">
                  <div className="newsletter_title">Lets get started</div>
                  <div className="newsletter_call animate-charcter">
                    <Link to={`tel:+${number}`}>{number}</Link>
                  </div>
                  <div className="newsletter_subtitle">
                    <b>Got a question? Call us! </b>
                    <br />
                    We are here for you round the clock with our assistance for
                    driving career!
                  </div>
                </div>
                {/* Newsletter Form */}
                <div className="newsletter_form_container ml-lg-auto">
                  <img src="/images/sample.png" alt="universityguroo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
