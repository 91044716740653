import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Parser from "../Elements/Parser";
import { baseUrl } from "../../config";

export default function BlogList({ data }) {
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Track the current page
  const chunkSize = 18;

  // Chunk the data into pages
  useEffect(() => {
    if (data && data.length > 0) {
      const chunks = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        chunks.push(data.slice(i, i + chunkSize));
      }
      setResultData(chunks);
    }
  }, [data]);

  // Function to change the page
  const handlePageChange = (pageIndex) => {
    if (pageIndex >= 0 && pageIndex < resultData.length) {
      setCurrentPage(pageIndex);
    }
  };

  // Generate pagination numbers with ellipses
  const getPageNumbers = () => {
    const totalPages = resultData.length;
    const pageNumbers = [];
  
    // Always show the first page
    pageNumbers.push(0);
  
    if (totalPages <= 7) {
      // If total pages are 7 or less, show all page numbers
      for (let i = 1; i < totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Show ellipsis before current page block if necessary
      if (currentPage > 3) {
        pageNumbers.push("...");
      }
  
      // Pages around the current page (2 before and 2 after)
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(currentPage + 2, totalPages - 2);
  
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
  
      // Show ellipsis after current page block if necessary
      if (currentPage < totalPages - 4) {
        pageNumbers.push("...");
      }
  
      // Always show the last page
      pageNumbers.push(totalPages - 1);
    }
  
    return pageNumbers;
  };
  

  return (
    <div className="blog_post_wrap">
      <div className="row">
        {resultData.length > 0 &&
          resultData[currentPage].map((blog, index) => (
            <div className="col-lg-4" key={index}>
              <div className="blog_post">
                <div className="blog_post_image">
                  <img src={`${baseUrl}${blog.image}`} alt="" />
                </div>
                <div className="blog_post_body">
                  <div className="blog_post_title">
                    <Link to={`/blog-details/${blog.title}`}>{blog.title}</Link>
                  </div>
                  <div className="blog_post_meta">
                    <ul>
                      <li>
                        <Link to="#">{blog.published_by}</Link>
                      </li>
                      <li>
                        <Link to="#">{blog.date_time}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="blog_post_text">
                    <p>
                      <Parser content={blog.description} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* Pagination Controls */}
      {resultData.length > 1 && (
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="Page navigation">
              <ul className="pagination">
                {/* Previous Button */}
                <li className={currentPage === 0 ? "disabled" : ""}>
                  <Link
                    to="#"
                    aria-label="Previous"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <span className="fa fa-angle-left" aria-hidden="true" />
                  </Link>
                </li>

                {/* Render pagination numbers */}
                {getPageNumbers().map((page, index) =>
                  page === "..." ? (
                    <li key={index} className="disabled">
                      <span>...</span>
                    </li>
                  ) : (
                    <li
                      key={page}
                      className={currentPage === page ? "active" : ""}
                    >
                      <Link to="#" onClick={() => handlePageChange(page)}>
                        {page + 1}
                      </Link>
                    </li>
                  )
                )}

                {/* Next Button */}
                <li
                  className={
                    currentPage === resultData.length - 1 ? "disabled" : ""
                  }
                >
                  <Link
                    to="#"
                    aria-label="Next"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <span className="fa fa-angle-right" aria-hidden="true" />
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
}
