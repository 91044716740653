import React, { useEffect, useState } from "react";
import CommonLayout from "../layouts/CommonLayout";
import Style from "../assets/styles/ModuleStyle/course.module.css";
import UniversityList from "../components/OnlineMbaComponents/UniversityList";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import { apiBaseUrl } from "../config";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOnlineMba } from "../reducer/onlineMbaSlice";
import uniqid from 'uniqid'

function OnlineMba() {
  const { searchTerm } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const mbaDetails = useSelector((state) => state.onlinemba?.data);
  const fetchGeneralCourse = async () => {
    try {
      const res = await fetch(
        `${apiBaseUrl}footer-course-details/${searchTerm}`
      );
      const data = await res.json();
      if (data.status === "success") {
        dispatch(setOnlineMba(data));
      } else {
        console.error("Invalid response format", data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    if (searchTerm) {
      fetchGeneralCourse();
    }
  }, [searchTerm]);

  if (loading) {
    return <div>Loading...</div>; // Optionally, show a loading spinner here
  }
  return (
    <CommonLayout>
      {mbaDetails && (
        <>
          <div className={`${Style.university} ${Style.best_colleges}`}>
            <div
              className={`${Style.breadcrumbs_container} ${Style.Allcourses_crumbs}`}
            >
              <div className="container">
                <div className="row">
                  <div className="col">
                    <p>
                      Checkout Some Most Searched University for Online MBA{" "}
                    </p>
                    <div className={`${Style.university_title} Omhighlight`}>
                      {mbaDetails?.seo_page?.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UniversityList key={uniqid()} data={mbaDetails?.seo_page_details[0]}/>
          <ExpertAdvice />
          <InNews />
          <NewsLetter />
        </>
      )}
    </CommonLayout>
  );
}

export default OnlineMba;
