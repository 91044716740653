import React from "react";
import CommonLayout from "../layouts/CommonLayout";
import HomeSlider from "../components/HomeSlider";
import VideoWrap from "../components/VideoWrap";
import FeaturedUniversity from "../components/FeaturedUniversity";
import AllUniversityLogo from "../components/AllUniversityLogo";
import TopUniversity from "../components/TopUniversity";
import Counter from "../components/Counter";
import CompareUniversity from "../components/CompareUniversity";
import LatestUpdates from "../components/LatestUpdates";
import Blogs from "../components/Blogs";
import ExpertAdvice from "../components/ExpertAdvice";
import StudentManSe from "../components/StudentManSe";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";

const Home = () => {
  return (
    <CommonLayout>
      <HomeSlider />
      <VideoWrap />
      <FeaturedUniversity />
      <AllUniversityLogo />
      <TopUniversity />
      <Counter />
      <CompareUniversity />
      <LatestUpdates />
      <Blogs />
      <ExpertAdvice />
      <StudentManSe />
      <InNews />
      <NewsLetter />
    </CommonLayout>
  );
};

export default Home;
