import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  studentmanseDatafirst:[]
};

export const studentManseSlice = createSlice({
  name: "studnetmanse",
  initialState,
  reducers: {
    setStudentManseDataFirst:(state,action)=>{
        state.studentmanseDatafirst=action.payload
    }
  },
});

export const {
    setStudentManseDataFirst
} = studentManseSlice.actions;
export default studentManseSlice.reducer;
