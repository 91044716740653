import React from 'react'
import Style from "../../assets/styles/ModuleStyle/course.module.css"
import Parser from '../Elements/Parser'

export default function Subjects({data}) {
  return (
   <div id="subject" className={`section ${Style.general_width} spec_highlight bg-light`}>
  <div className="container" data-aos="fade-in">
    <div className="row">
      <div className="col">
        <div name="subject" style={{paddingTop: 70}} className="mb-5 row">
        <Parser content={data && data} />
        </div>
      </div>
    </div>
  </div>
</div>

  )
}
