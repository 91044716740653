import React from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";

export default function CourseNameDetails({
  rating,
  courseName,
  universityName,
  reviewCount = 600,
}) {
  return (
    <>
      <div className={`${CrStyle.university} course-breadcrumb`}>
        <div className={CrStyle.breadcrumbs_container}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className={CrStyle.university_title}>
                  <span>{universityName}</span> {courseName}
                </div>
                <div
                  className={`${CrStyle.university_info} d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start`}
                >
                  {/* Course Info Item */}
                  <div className={CrStyle.university_info_item}>
                    <div
                      className={`${CrStyle["rating_r"]} ${CrStyle.rating_r_4} `}
                    >
                      <div className="stars-common-outer">
                        <div
                          className="stars-common-inner"
                          style={{ width: `${(rating && rating / 5) * 100}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {/* Course Info Item */}
                  <div
                    className={`${CrStyle.university_info_item} ${CrStyle["review-wrap"]} d-flex flex-lg-row align-items-lg-center align-items-start  justify-content-around px-0`}
                  >
                    <div className={CrStyle.university_info_title}>
                      <span>
                        {reviewCount === null || reviewCount === ""
                          ? "000"
                          : reviewCount}
                      </span>{" "}
                      Reviews
                    </div>
                    <div className={CrStyle.divider} />
                    <div className={CrStyle.university_info_text}>
                      <Link to="#review_wrapper">
                        <i className="uil uil-edit-alt gelatine" /> Write a
                        Review
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
