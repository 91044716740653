import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css"
import { baseUrl } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import Parser from "../Elements/Parser";


export default function CourseValue({data}) {
  const navigate = useNavigate()
  return (
    <div id="overview" className={`${Style.specialization_info} ${Style.general_width} mt-5`}>
      <div className="container" data-aos="fade-in">
        <div className="row py-3">
          <div className="col-md-4">
            <img
              alt=""
              className={`img-fluid ${Style["border-radius"]} border-radius`}
              src={`${baseUrl}${data?.overview_image}`}
            />
          </div>
          <div className="col-md-8">
            <div className="special_info_basic">
              <p className={`${Style["update-col"]} text-secondary mb-2 d-flex justify-content-between flex-wrap gap-2`}>
                {/* api is missing */}
                <span>
                  <span className="text-uppercase"> Updated at</span> : {data?.date_time}
                </span>
                <span
                  className={`${Style["info-btn"]} btn btn-primary text-white px-2 py-2 rounded shadow cursor-pointer d-flex align-items-center gap-1 fw-bold `}
                  height="1em"
                  width="1em"
                  onClick={()=>navigate(data[0]?.canonical_url)}
                >
                  DOWNLOAD E-BOOK
                </span>
              </p>
              {/* <h3>{data[0]?.name} </h3> */}
              <Parser content={data?.overiew_content}/>
              <div className="row pt-4">
                <div className="col mb-2 col">
                  <p className="m-0 cursor-pointer d-flex align-items-center gap-2 fw-bold">
                    <Link className=" d-md-block" target="_blank" to={data?.video_link} style={{cursor:'pointer'}} >
                      Watch Video
                      <img
                        alt=""
                        className="img-fluid border-radius"
                        src="/images/video.png"
                      />
                    </Link>
                  </p>
                </div>
                <div className="col mb-2 col">
                  <p className="m-0 cursor-pointer d-flex align-items-center gap-2 fw-bold">
                    <Link className="d-md-block" target="_blank" to={data?.audio_link} style={{cursor:'pointer'}} >
                      Listen Audio
                      <img
                        alt=""
                        className="img-fluid border-radius"
                        src="/images/audio.png"
                      />
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
