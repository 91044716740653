import React, { useEffect, useRef } from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import $ from "jquery";
import { baseUrl } from "../../config";
import { Link } from "react-router-dom";
export default function SideBar({ data }) {
  const scrollGroupRef = useRef(null);

  useEffect(() => {
    const $scrollGroup = $(scrollGroupRef.current);
    const newsNum = $scrollGroup.find("li").length;

    let totalNews_H = 0;
    for (let i = 0; i < newsNum; i++) {
      const news_H = $scrollGroup.find("li").eq(i).outerHeight(true);
      totalNews_H += news_H;
    }

    $scrollGroup.css("height", totalNews_H + "px");

    const speed = 1;
    let animAmount;
    let animInterval;

    const animateNews = () => {
      $scrollGroup
        .find("li")
        .eq(0)
        .animate(
          {
            marginTop: `-=${speed}px`,
          },
          1,
          function () {
            animAmount = speed;
            const animNews_H = $(this).outerHeight(true);

            if (animAmount >= animNews_H) {
              $(this).parent().append($(this));
              $(this).removeAttr("style");
            }

            animInterval = setTimeout(() => {
              animateNews();
            }, 50);
          }
        );
    };

    animateNews();

    $scrollGroup.hover(
      () => {
        clearTimeout(animInterval);
        $scrollGroup.find("li").eq(0).stop();
      },
      () => {
        animateNews();
      }
    );
  }, []);

  return (
    <div className="col-md-3">
      <div className={Style["sidebar-item"]}>
        <div className="inner expertss">
          {/*	<h3 className=text-center fs-5 section_title mb-4">Other Career Experts</h3>*/}
          <div className={`${Style["sidebar_section_title"]} mb-4`}>
            Other Career Experts
          </div>
          <ul className={Style.scrollgroup} ref={scrollGroupRef}>
            {data !== undefined &&
              data.map((item, index) => (
                <li
                key={index}
                  className={`${Style.scrollitem} ${Style["other-expert-row"]} d-flex d-flex justify-content-start`}
                >
                  <div className={Style["ot-img"]}>
                    <img
                      src={`${baseUrl}${item.image}`}
                      className={`img-fluid ${Style["other-expert-img"]}`}
                      alt=""
                    />
                  </div>
                  <div className={Style["ot-ex-details"]}>
                    <a className={Style["expt-name"]} href>
                      {item.name}
                    </a>
                    <ul className={Style["other-career-expert"]}>
                      <li>
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#modalLoginForm2"
                        >
                          Consult Now
                        </a>
                      </li>
                      <li>
                        <Link to={`/expert/${item.id}`} className="color-blue">
                          Know More
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ))}
          </ul>
          {/* End .product-desc-wrapper */}
        </div>
      </div>
    </div>
  );
}
