import React from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";
import { setModalDynamicImg } from "../../reducer/universitySlice";
import { useDispatch } from "react-redux";

export default function CourseFee({
  heading = "Course Fee ",
  courseList,
  totalfees,
  courseName,
  specializaion,
}) {
  const dispatch = useDispatch();
  return (
    <div id="Compare" className="uni_program_wrapper">
      <div className="title mt-5">
        <h3>{heading}</h3>
      </div>
      <div className="program_university mt-3">
        <div className="container">
          <div className={`row row--10 ${CrStyle["contact-university-now"]}`}>
            <div className={`row row--10 ${CrStyle["headingss"]}`}>
              <div
                className={`col-md-4 ${CrStyle.poffer} ${CrStyle.pofferone}`}
              >
                <span>
                  <strong>Course</strong>
                </span>
              </div>
              <div
                className={`col-md-4 ${CrStyle.poffer} ${CrStyle.poffertwo}`}
              >
                <span>
                  <strong>Total Fee</strong>
                </span>
              </div>
              <div
                className={`col-md-4 ${CrStyle.poffer} ${CrStyle.pofferthree} ${CrStyle["full-width"]}`}
              >
                <span>
                  <strong>Compare</strong>
                </span>
              </div>
            </div>
            {courseList?.map((item, index) => (
              <div key={index}  className={`row row--10 ${CrStyle.contentss}`}>
                <div
                  className={`col-md-4 ${CrStyle.poffer} ${CrStyle.pofferone}`}
                >
                  <Link to className="">
                    <span>{item.coursename}</span>
                  </Link>
                  <span>{item.specialization}</span>
                </div>
                <div className="col-md-4 poffer poffertwo">
                  <span>{item.totalfees}</span>
                </div>
                <div
                  className={`col-md-4 poffer ${CrStyle.pofferthree} full-width zoom-gallery`}
                >
                  <p>
                    Add to Compare{" "}
                    <input type="checkbox" className="add-to-compare" />
                  </p>
                  <Link
                    to
                    data-toggle="modal"
                    data-target="#modalLoginImg"
                    className="gyan-link popup-zoom"
                    onClick={() =>
                      dispatch(
                        setModalDynamicImg(
                          item.completefees
                        )
                      )
                    }
                  >
                    View Fee Breakup
                  </Link>{" "}
                  /
                  <Link
                    to
                    className="gyan-link"
                    data-toggle="modal"
                    data-target="#modalLoginForm2"
                  >
                    Get Offer
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
