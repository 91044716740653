import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  compareData:[]
};

export const compareUniversitySlice = createSlice({
  name: "compareUni",
  initialState,
  reducers: {
    setCompareUniversity:(state,action)=>{
        state.compareData=action.payload
    }
  },
});

export const {
    setCompareUniversity
} = compareUniversitySlice.actions;
export default compareUniversitySlice.reducer;
