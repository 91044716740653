import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  exploreDetails:{}
};

export const expoloreCategorySlice = createSlice({
  name: "explore",
  initialState,
  reducers: {
    setCategoryDetails:(state,action)=>{
        state.exploreDetails=action.payload
    }
  },
});

export const {
    setCategoryDetails
} = expoloreCategorySlice.actions;
export default expoloreCategorySlice.reducer;
