import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";

export default function SingleRecentPost({ title, date }) {
  return (
    <div className={Style.latest}>
      <div className={Style.latest_content}>
        <div className={Style.latest_title}>
          <Link to={`/blog-details/${title}`}>{title}</Link>
        </div>
        <div className={Style.latest_date}>{date}</div>
      </div>
    </div>
  );
}
