import React from "react";
import OwlCarousel from "react-owl-carousel";
import owlStyle from "../../assets/styles/ModuleStyle/customOwl.module.css";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import { responsiveCarousel } from "../../utils/constants";
import { baseUrl } from "../../config";
import { setModalDynamicImg } from "../../reducer/universitySlice";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

export default function UniversityCourseCarousel({ courses, logo }) {
  const { uniName } = useParams();
  const dispatch = useDispatch();
  return (
    <div
      className="Unversity_courses_Wrapp"
      data-aos="fade-up"
      data-aos-delay={100}
    >
      <div className="container">
        <div className="row mb-4">
          <h3>University Course</h3>
        </div>
        <div className="row">
          <OwlCarousel
            responsive={responsiveCarousel}
            id="courses_university"
            // loop
            nav
            dots={false}
            stageOuterClass={owlStyle.owlStageOuterSpec}
            navText={[
              "<i class='fa fa-chevron-left'></i>",
              "<i class='fa fa-chevron-right'></i>",
            ]}
            navContainerClass={owlStyle.containerNav}
            navClass={["navPrev", "navNext"]}
            className="owl-carousel owl-theme row"
          >
            {courses?.map((item, index) => (
              <div className="course_infoo item col" key={index}>
                <div className={Style.course_body}>
                  <div className={Style["unicourse-logo"]}>
                    <img src={logo && logo} alt="" />
                  </div>
                  <div className={Style.courses_image}>
                    <img src={`${baseUrl}${item.image}`} alt="" />
                  </div>
                  <div className={Style["cour-university_body"]}>
                    <h3 className={Style["cour-university_title"]}>
                      {item.name}
                    </h3>
                    <span className={Style["course-amt"]}>
                      {item.fees_amount}
                    </span>
                    <a
                      className={`${Style.btn} btn ${Style["view-fees"]}`}
                      href
                      data-toggle="modal"
                      data-target="#modalLoginImg"
                      onClick={() =>
                        dispatch(
                          setModalDynamicImg(`${baseUrl}${item?.complete_fees}`)
                        )
                      }
                    >
                      View Total Fees
                    </a>
                  </div>
                </div>
                <div className={Style["details-footer"]}>
                  <Link to={`/course/${uniName}/${item.name}`} style={{ color: "#fff" }}>
                    Show Complete Details
                  </Link>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
}
