import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiBaseUrl } from "../config";
import { setStudentManseDataFirst } from "../reducer/studentManseSlice";
import uniqid from "uniqid";
import OwlCarousel from "react-owl-carousel";

export default function StudentManSe() {
  const dispatch = useDispatch();
  const manseList = useSelector(
    (state) => state.studentmanse?.studentmanseDatafirst
  );
  const [loading, setLoading] = useState(false);
  const fetchStudentManse = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}student-manse`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          dispatch(setStudentManseDataFirst(data && data.student_manse));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchStudentManse();
  }, []);
  return (
    <>
      <div className="student_mannse background-dark section">
        <div className="container" data-aos="fade-in">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h2 className="section_title">Students Mann Se!</h2>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {loading ? (
              <h3>Loading...</h3>
            ) : (
              <OwlCarousel
                autoplay
                autoplaySpeed={1000}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 2,
                  },
                }}
                dots={false}
                id="student_mannseblog"
                className="owl-carousel owl-theme"
              >
                {manseList &&
                  manseList.map((item, index) => (
                    <div
                      key={uniqid()}
                      className="item col-lg-12"
                      data-aos="fade-in"
                      data-aos-delay={100}
                    >
                      <div className="student-box">
                        <h6 className="student-mannse-Title mb-4">
                          <i className="fa fa-quote-left student-mannse-sign" />
                          {item.name}
                        </h6>
                        <div
                          className="Blog-View-Desc"
                          style={{ overflow: "auto" }}
                        >
                          {item.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
