import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  universityNewsList:[]
};

export const universityNewsSlice = createSlice({
  name: "expertreviews",
  initialState,
  reducers: {
    setUniversityNews:(state,action)=>{
        state.universityNewsList=action.payload
    }
  },
});

export const {
    setUniversityNews
} = universityNewsSlice.actions;
export default universityNewsSlice.reducer;
