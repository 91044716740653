import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CommonModal from "../components/Modals/CommonModal";
import ModalImg from "../components/Modals/ModalImg";
import CourseMainModal from "../components/Modals/CourseMainModal";

export default function CommonLayout({ children }) {
 
  return (
    <>
      <div className="super_container">
        <Header />
        {children}
        <Footer />
      </div>

      <CommonModal />
      <ModalImg />
      <CourseMainModal />
    </>
  );
}
