import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expertReviewList:[]
};

export const expertReviewsSlice = createSlice({
  name: "expertreviews",
  initialState,
  reducers: {
    setExpertReview:(state,action)=>{
        state.expertReviewList=action.payload
    }
  },
});

export const {
  setExpertReview
} = expertReviewsSlice.actions;
export default expertReviewsSlice.reducer;
