import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import OpenModalCommon from "../Elements/OpenModalCommon";
import Parser from "../Elements/Parser";

export default function AboutMe({ data }) {
  return (
    <div className="col-md-9">
      <div className="row mb-3">
        <div className="title">
          <h3 className="section_title">About Me</h3>
        </div>
      </div>
      <div className={`${Style.Expert_info} p-4 pb-4`}>
        <div className="container" data-aos="fade-in">
          <div className="row">
            <div className="col-md-12">
              <Parser content={data && data} />
            </div>
          </div>
        </div>
      </div>
      <OpenModalCommon />
      <div className="review_expert section">
        <div className="container" data-aos="fade-in">
          <div className="row mb-3">
            <div className="col-md-12">
              <h3 className="section-title">Write a Review</h3>
            </div>
          </div>
          <div className="row">
            <div className={Style.expert_form}>
              <div className="card border-none rounded-6">
                <div className="card-body row p-3">
                  {/*Body*/}
                  <div className={`${Style["form-group"]} col-12 col-lg-6`}>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div
                          className={`${Style["input-group-text"]} input-group-text`}
                        >
                          <i className="fa fa-user text-info" />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="nombre"
                        name="name"
                        placeholder="Full Name"
                        required
                      />
                    </div>
                  </div>
                  <div className={`${Style["form-group"]} col-12 col-lg-6`}>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div
                          className={`${Style["input-group-text"]} input-group-text`}
                        >
                          <i className="fa fa-envelope text-info" />
                        </div>
                      </div>
                      <input
                        type="email"
                        className="form-control"
                        id="nombre"
                        name="email"
                        placeholder="example@gmail.com"
                        required
                      />
                    </div>
                  </div>
                  <div className={`${Style["form-group"]} col-12 col-lg-6`}>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div
                          className={`${Style["input-group-text"]} input-group-text`}
                        >
                          <i className="fa fa-mobile text-info" />
                        </div>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        id="nombre"
                        name="phone"
                        placeholder="91 9876 543 210"
                        required
                      />
                    </div>
                  </div>
                  <div className={`${Style["form-group"]} col-12 col-lg-6`}>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div
                          className={`${Style["input-group-text"]} input-group-text`}
                        >
                          <i className="fa fa-user-circle text-info" />
                        </div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="nombre"
                        name="expert"
                        placeholder="Expert Name"
                        required
                      />
                    </div>
                  </div>
                  <div className={`${Style["form-group"]} col-12 col-lg-6`}>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div
                          className={`${Style["input-group-text"]} input-group-text`}
                        >
                          <i className="fa fa-comment text-info" />
                        </div>
                      </div>
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        required
                        defaultValue={""}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <input
                      type="submit"
                      defaultValue="Submit"
                      className="btn btn-info btn-block rounded-0 py-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*----Get Advice from Expert !----*/}
    </div>
  );
}
