import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  compareList:[]
};

export const comparePageSlice = createSlice({
  name: "compareUniversities",
  initialState,
  reducers: {
    setCompareUniversities:(state,action)=>{
        state.compareList=action.payload
    }
  },
});

export const {
    setCompareUniversities
} = comparePageSlice.actions;
export default comparePageSlice.reducer;
