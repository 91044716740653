import React from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";

export default function CourseSpecification({ title, boxValue }) {
  return (
    <div id="Specification" className="university_key_spec mt-lg-5 mt-4">
      <div className="row">
        <h3>{title}</h3>
      </div>
      <div className="row">
        {boxValue?.map((item) => (
          <div className="keyy-speccol col-lg-4 col-6">
            <div className={CrStyle.key_box}>
              <p>
                <b>{item.title} </b>
              </p>
              <p>{item.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
