import { configureStore } from "@reduxjs/toolkit";
import featuredUniversitySlice from "../reducer/featuredUniversitySlice";
import inNewsSlice from "../reducer/inNewsSlice";
import compareUniversitySlice from "../reducer/compareUniversitySlice";
import blogSlice from "../reducer/BlogSlice";
import studentManseSlice from "../reducer/studentManseSlice";
import expertReviewsSlice from "../reducer/expertReviewsSlice";
import expertAdviceSlice from "../reducer/expertAdviceSlice";
import universityNewsSlice from "../reducer/UniversityNewsSlice";
import allUniversitySlice from "../reducer/allUniversitySlice";
import headerSlice from "../reducer/headerSlice";
import universitySlice from "../reducer/universitySlice";
import courseSlice from "../reducer/courseSlice";
import expertSlice from "../reducer/expertSlice";
import expoloreCategorySlice from "../reducer/expoloreCategorySlice";
import comparePageSlice from "../reducer/comparePageSlice";
import generalSpecializationSlice from "../reducer/generalSpecializationSlice";
import onlineMbaSlice from "../reducer/onlineMbaSlice";

export const store = configureStore({
  reducer: {
    featured: featuredUniversitySlice,
    inNews: inNewsSlice,
    compare: compareUniversitySlice,
    blogs: blogSlice,
    studentmanse: studentManseSlice,
    expertReviews: expertReviewsSlice,
    expertAdvice: expertAdviceSlice,
    universityNews: universityNewsSlice,
    allUniversity: allUniversitySlice,
    header: headerSlice,
    mainuniversity: universitySlice,
    course: courseSlice,
    expert: expertSlice,
    explore: expoloreCategorySlice,
    compareUniversities: comparePageSlice,
    generalSc: generalSpecializationSlice,
    onlinemba:onlineMbaSlice
  },
});
