import React, { useEffect } from "react";
import CommonLayout from "../layouts/CommonLayout";
import ExpertWrapper from "../components/ExpertComponents/ExpertWrapper";
import AboutMe from "../components/ExpertComponents/AboutMe";
import SideBar from "../components/ExpertComponents/SideBar";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import { apiBaseUrl } from "../config";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setExpert } from "../reducer/expertSlice";

export default function Expert() {
  const dispatch = useDispatch();
  const { expertId } = useParams();
  const expertD = useSelector(
    (state) => state.expert?.expertdetails?.expertDetails
  );
  const others = useSelector((state)=>state.expert?.expertdetails?.otherExperDetails)
  const fetchExpert = async () => {
    try {
      const res = await fetch(`${apiBaseUrl}expert-details/${expertId}`);
      const data = await res.json();
      if (data.status === "Success") {
        dispatch(setExpert({ ...data }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (expertId) {
      fetchExpert();
    }
  }, [expertId]);
  return (
    <CommonLayout>
      <ExpertWrapper data={expertD && expertD} />
      <div className="experts_two_wrap mt-5">
        <div className="container" data-aos="fade-in">
          <div className="row">
            <AboutMe data={expertD && expertD?.description}/>
            <SideBar data={others && others}/>
          </div>
        </div>
      </div>
      <ExpertAdvice getModal="#modalLoginForm2" />
      <InNews />
      <NewsLetter />
    </CommonLayout>
  );
}
