import React from "react";
import UniversitySummary from "./UniversitySummary";
import FacultyList from "./FacultyList";

export default function FacultyDetails({ text, uniLogo, facultyList }) {
  return (
    <>
      <UniversitySummary
        mainId="faculty"
        mainclassName="uni_faculty_wrapper mt-5"
        defaultShow={text}
        title="Faculty"
        isButton={false}
      />
      <FacultyList
        uniLogo={uniLogo && uniLogo}
        facultyList={facultyList && facultyList}
      />
    </>
  );
}
