import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseInfo: {},
  //   universityId: "",
  //   modalDynamicImg: "",
};

export const courseSlice = createSlice({
  name: "particularCourse",
  initialState,
  reducers: {
    setCourse: (state, action) => {
      state.courseInfo = action.payload;
    },
  },
});

export const { setCourse } = courseSlice.actions;
export default courseSlice.reducer;
