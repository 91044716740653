import React, { useEffect } from "react";
import CommonLayout from "../layouts/CommonLayout";
import Style from "../assets/styles/ModuleStyle/course.module.css";
import CompareSelect from "../components/Comparison/CompareSelect";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiBaseUrl } from "../config";
import { setCompareUniversities } from "../reducer/comparePageSlice";
import uniqid from "uniqid";

function Comparison() {
  const { universityIds } = useParams();
  const dispatch = useDispatch();

  const fetchCompareUniversities = async () => {
    fetch(`${apiBaseUrl}compare-univercity-details/${universityIds}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          // setUniversity(data);
          dispatch(setCompareUniversities(data));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (universityIds) {
      fetchCompareUniversities();
    }
  }, [universityIds]);

  const comparisonData = useSelector(
    (state) => state.compareUniversities?.compareList
  );

  return (
   
      <CommonLayout>
        <div className={Style.compair_wrap_breadcrumb}>
          <div className={`${Style.breadcrumbs_container} compair_crumbs`}>
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className={Style.compare_details}>
                    <div className={Style.university_title}>
                      {comparisonData?.coparison_heading}
                    </div>
                    <div className="university_disc">
                      University Guroo bring you the detailed comparison of the
                      below renowned universies
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CompareSelect key={uniqid()} data={comparisonData && comparisonData} />
        <ExpertAdvice />
        <InNews />
        <NewsLetter />
      </CommonLayout>
  );
}

export default React.memo(Comparison);
