import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expertdetails:{}
};

export const expertSlice = createSlice({
  name: "expert",
  initialState,
  reducers: {
    setExpert:(state,action)=>{
        state.expertdetails=action.payload
    }
  },
});

export const {
    setExpert
} = expertSlice.actions;
export default expertSlice.reducer;
