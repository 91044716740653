import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data:[]
};

export const onlineMbaSlice = createSlice({
  name: "onlinembapage",
  initialState,
  reducers: {
    setOnlineMba:(state,action)=>{
        state.data=action.payload
    }
  },
});

export const {
    setOnlineMba
} = onlineMbaSlice.actions;
export default onlineMbaSlice.reducer;
