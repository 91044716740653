import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gsc:{}
};

export const generalSpecializationSlice = createSlice({
  name: "gsc",
  initialState,
  reducers: {
    setGsc:(state,action)=>{
        state.gsc=action.payload
    }
  },
});

export const {setGsc} = generalSpecializationSlice.actions;
export default generalSpecializationSlice.reducer;
