import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { navScroller } from "../../utils/subheaderscroller";
import { HashLink } from "react-router-hash-link";

const SubHeader = ({ subheaderContent }) => {
  const [scrolled, setScrolled] = useState(false);
  const [selected, setSelected] = useState("");
  const subheaderRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (subheaderRef.current) {
      navScroller({
        wrapperSelector: subheaderRef.current,
        selector: ".nav-scroller",
        contentSelector: ".nav-scroller-content",
        buttonLeftSelector: ".nav-scroller-btn--left",
        buttonRightSelector: ".nav-scroller-btn--right",
        scrollStep: 75,
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const viewportHeight = window.innerHeight;
      const offset = viewportHeight * 0.15; 
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        const viewportHeight = window.innerHeight;
        const offset = viewportHeight * 0.15; 
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;
  
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    }
  }, [location.hash]);
  

  return (
    <>
      <div
        className="sub_header"
        style={
          scrolled ? { position: "fixed", top: "0px", background: "#fff" } : {}
        }
      >
        <nav className="navbar navbar-expand-sm sticky-top navbar-light">
          <div className="container" ref={subheaderRef}>
            <div className="nav-scroller-wrapper">
              <div className="nav-scroller">
                <div className="nav-scroller-content">
                  {subheaderContent &&
                    subheaderContent.map((item) => (
                      <HashLink
                        key={item.title}
                        to={item.link} // Optionally keep this for fallback
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection(item.link.replace("#", ""));
                          setSelected(item.link);
                        }}
                        className={
                          selected === item.link
                            ? "nav-scroller-item active"
                            : "nav-scroller-item"
                        }
                      >
                        {item.title}
                      </HashLink>
                    ))}
                </div>
              </div>
              <button className="btn btn-sm btn-primary nav-scroller-btn nav-scroller-btn--left">
                <i className="uil uil-angle-left" />
              </button>
              <button className="btn btn-sm btn-primary nav-scroller-btn nav-scroller-btn--right">
                <i className="uil uil-angle-right" />
              </button>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default React.memo(SubHeader);
