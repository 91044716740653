import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css"
import Parser from "../Elements/Parser";

export default function Eligibility({ data }) {
  return (
    <div
      id="eligibility"
      className={`section ${Style.general_width} spec_eligibility bg-white`}
    >
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col">
            <Parser content={data && data} />
          </div>
        </div>
      </div>
    </div>
  );
}
