import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogList:[]
};

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlogListData:(state,action)=>{
        state.blogList=action.payload
    }
  },
});

export const {
    setBlogListData
} = blogSlice.actions;
export default blogSlice.reducer;
