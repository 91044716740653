export function getMonthDay(date, resultfor) {
  const dateNew = new Date(date);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dateValue = dateNew.getDate();
  const monthValue = dateNew.getMonth();
  const monthName = monthNames[monthValue];

  if (resultfor === "day") {
    return dateValue;
  }
  if (resultfor === "month") {
    return monthName;
  }
}

export function getShortForm(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
}

