import React, { useEffect, useState } from "react";
import { apiBaseUrl, baseUrl } from "../config";
import { Link } from "react-router-dom";



function VideoWrap() {
  const [bannerObj,setBannerObj]=useState({});
  const fetchAddBanner = () => {
    fetch(`${apiBaseUrl}add-banner`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setBannerObj(data?.banner_list[0])
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(()=>{fetchAddBanner()},[])
  return (
    <>
      {/*- Video Wrap --*/}
      <div className="Videowrap">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12 text-center">
              <div className="Videocol_video d-flex flex-column align-items-center justify-content-center">
                <div className="Videocol_video_background">
                  <img
                    className="img-responsive"
                    src={`${baseUrl}${bannerObj.image}`}
                    alt=""
                  />
                </div>
                {/*<a className=vimeo Videocol_video_button cboxElement" href="https://youtu.be/5OfOJpSWUP4?si=iMsDxBcOqIXfkepe" title="Your Degree, Your Way: The Amrita Ahead Advantage">
                     <img src="images/play.png" alt="">
                     </a>*/}
                <div className="vimeo Videocol_video_button wrapper">
                  <div className="video-main">
                    <div className="promo-video">
                      <div className="waves-block">
                        <div className="waves wave-1" />
                        <div className="waves wave-2" />
                        <div className="waves wave-3" />
                      </div>
                    </div>
                    <Link
                      to="https://youtu.be/5OfOJpSWUP4?si=iMsDxBcOqIXfkepe"
                      title="Your Degree, Your Way: The Amrita Ahead Advantage"
                      className="video video-popup mfp-iframe"
                      data-lity
                    >
                      <i className="fa fa-play" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoWrap;
