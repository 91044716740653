import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobileHeaderContext from "../../Context/MobileHeaderContext";


const MobileHeaderItemAccordion = ({ title, link, item,isMenuOpen }) => {
  const navigate = useNavigate();
  const {
    accordionContent,
    setAccordionContent,
    currentAccordion,
    setCurrentAccordion,
    AccordionArr,
    setAccordionArr
  } = useContext(MobileHeaderContext);
  function handleItems(){
    setAccordionContent(item.items)
    setCurrentAccordion(item.name)
    setAccordionArr([...AccordionArr, ""]);
  }
  function handleLinkClick(e,link){
    if(link === "#"){
      e.preventDefault()
    }else{
      navigate(link)
      isMenuOpen(false)
    }
  }
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "6px 0px" }}
      >
        {link ? (
          <Link
            to={link}
            onClick={(e) => handleLinkClick(e,link)}
            className="d-flex justify-content-between align-items-center w-100"
          >
            <div style={{ fontSize: "18px" }}>
              <p>{title}</p>
            </div>
            <div>
              <i className="fa-solid fa-angle-right"></i>
            </div>
          </Link>
        ) : (
          <div onClick={handleItems} className="d-flex justify-content-between align-items-center w-100">
            <div  style={{ fontSize: "18px" }}>
              <p>{title}</p>
            </div>
            <div>
              <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(MobileHeaderItemAccordion);
