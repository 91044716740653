import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import owlStyle from "../assets/styles/ModuleStyle/customOwl.module.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiBaseUrl, baseUrl } from "../config";
import { setBlogListData } from "../reducer/BlogSlice";
import uniqid from "uniqid";

export default function Blogs({ uniId }) {
  const [uniBlogList, setUniBlogList] = useState();
  const dispatch = useDispatch();
  const blogList = useSelector((state) => state.blogs?.blogList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (uniId && blogList) {
      setUniBlogList([
        ...blogList?.filter(
          (item, index) => item.universities_id === Number(uniId) && item
        ),
      ]);
    } else {
      setUniBlogList(blogList);
    }
  }, [uniId, blogList]);
  const fetchBlogList = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}blog-list`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          dispatch(setBlogListData(data && data.blog_list));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchBlogList();
  }, []);
  return (
    <>
      <div id="Blogs" className="University_blog section">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h2 className="section_title">Blogs</h2>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {loading ? (
              <h3>Loading...</h3>
            ) : (
              <OwlCarousel
                loop
                nav
                dots={false}
                stageOuterClass={owlStyle.owlStageOuterSpec}
                navText={[
                  "<i class='fa fa-chevron-left'></i>",
                  "<i class='fa fa-chevron-right'></i>",
                ]}
                navContainerClass={owlStyle.containerNav}
                navClass={["navPrev", "navNext"]}
                id="University_Blogs"
                className="owl-carousel owl-theme row"
                items={3}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                }}
              >
                {uniBlogList &&
                  uniBlogList.map((item, index) => (
                    <div
                      key={uniqid()}
                      className="blogwrap thumbnail item col fea_university_col"
                      data-aos="fade-in"
                      data-aos-delay={100}
                    >
                      <div className="fev-colleges">
                        <div className="fea-university_image">
                          <img src={`${baseUrl}${item.image}`} alt="" />
                        </div>
                        <div className="fea-university_body">
                          <h3 className="fea-university_title">
                            <Link to={`/blog-details/${item.title}`}>{item.title}</Link>
                          </h3>
                        </div>
                        <div className="fea-university_footer">
                          <div className="publishcol fea-university_footer_content d-flex flex-row align-items-center justify-content-between">
                            <p>By: {item.published_by}</p>
                            <p className="timeby">{item.ago} min. ago.</p>
                          </div>
                          <div className="blog-content">
                            <p>{item.description}</p>
                          </div>
                          <div className="fea-university-button">
                            <Link className="btn fea-button" to={`/blog-details/${item.title}`}>
                              Read More <i className="uil uil-angle-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
