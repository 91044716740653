import React, { useEffect } from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiBaseUrl, baseUrl } from "../../config";
import uniqid from "uniqid";
import { setAllUniversityList } from "../../reducer/allUniversitySlice";
import { useDispatch, useSelector } from "react-redux";

function CompareSelect({ data }) {
  const uniLogos = data?.univercity_logos;
  const uniDetails = data?.comparison_data_arr;
  const { universityIds } = useParams();

  const unilist = useSelector((state) => state.allUniversity?.allUniversity);

  const navigate = useNavigate();

  // Split the universityIds string into an array
  const universityIdsArray = universityIds.split(",");

  const handleDelete = (idToDelete) => {
    console.table(typeof universityIds, universityIds);
    // Filter out the university ID that needs to be deleted
    const updatedUniversityIdsArray = universityIdsArray.filter(
      (id) => String(id) !== String(idToDelete)
    );

    console.table("universityarrayupdate", updatedUniversityIdsArray);

    // Join the remaining IDs and update the URL
    const updatedUniversityIds = updatedUniversityIdsArray.join(",");
  

    // Navigate to the new URL with the updated university IDs
    navigate(`/comparison/${updatedUniversityIds}`);
  };

  const handleSelect = (uniIdToAdd) => {
    // Add the selected university id to the end of the array
    const updatedUniversityIdsArray = [
      ...universityIdsArray,
      String(uniIdToAdd),
    ];
    const updatedUniversityIds = updatedUniversityIdsArray.join(",");
    navigate(`/comparison/${updatedUniversityIds}`);
  };
  const dispatch = useDispatch();

  const fetchAllUniversity = () => {
    fetch(`${apiBaseUrl}all-univercity`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          dispatch(setAllUniversityList(data?.univercity_list));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error("Error fetching banner image:", error);
      });
  };

  useEffect(() => {
    fetchAllUniversity();
  }, []);

  return (
    <div className="compare_select">
      <div className="container">
        <div className="row compare-add-wrap">
          <div className="col-4 col-md-3">
            {/* <div className={`${Style["compare-box"]} h-100 w-100`}>
            <div className={`${Style["compare-remove"]}`}>
              <i className="uil uil-times-square" />
            </div>
            <div className={`${Style["compare-img"]}`}>
              {" "}
              <img src="/images/logo/Amritaug.png" alt="" />
            </div>
            <div className={`${Style["compare-text"]}`}>
              {" "}
              ₹40000 per year onwards
            </div>
          </div> */}
          </div>
          {/*----COl END----*/}
          {uniLogos && uniLogos.length > 0 && (
            <div className="col-4 col-md-3">
              <div className={`${Style["compare-box"]} h-100 w-100`}>
                {uniLogos?.length > 1 && (
                  <div
                    onClick={() => handleDelete(uniLogos[0].univercity_id)}
                    className={`${Style["compare-remove"]}`}
                  >
                    <i className="uil uil-times-square" />
                  </div>
                )}
                <div className={`${Style["compare-img"]}`}>
                  {" "}
                  <img
                    src={`${baseUrl}${uniLogos[0].univercity_logo}`}
                    alt=""
                  />
                </div>
                {/* <div className={`${Style["compare-text"]}`}>
                  {" "}
                  ₹40000 per year onwards
                </div> */}
              </div>
            </div>
          )}
          {/*----COl END----*/}
          {uniLogos && uniLogos.length > 1 ? (
            <div className="col-4 col-md-3">
              <div className={`${Style["compare-box"]} h-100 w-100`}>
                {uniLogos?.length > 1 && (
                  <div
                    onClick={() => handleDelete(uniLogos[1].univercity_id)}
                    className={`${Style["compare-remove"]}`}
                  >
                    <i className="uil uil-times-square" />
                  </div>
                )}
                <div className={`${Style["compare-img"]}`}>
                  {" "}
                  <img
                    src={`${baseUrl}${uniLogos[1].univercity_logo}`}
                    alt=""
                  />
                </div>
                {/* <div className={`${Style["compare-text"]}`}>
                  {" "}
                  ₹40000 per year onwards
                </div> */}
              </div>
            </div>
          ) : (
            <div
              className="col-4 col-md-3"
              data-toggle="modal"
              data-target="#allUniversityModal"
            >
              <div
                className={`${Style["compare-box"]} add-compare h-100 w-100 d-flex align-items-center justify-content-center flex-column`}
              >
                <div className={`${Style["compare-add-btn"]}  rounded-circle`}>
                  <i className="uil uil-plus" />
                </div>
                <div className={`${Style["compare-text"]}`}>
                  {" "}
                  Add To Compare
                </div>
              </div>
            </div>
          )}

          {/*----COl END----*/}
          {uniLogos && uniLogos.length > 2 ? (
            <div className="col-4 col-md-3">
              <div className={`${Style["compare-box"]} h-100 w-100`}>
                {uniLogos?.length > 1 && (
                  <div
                    onClick={() => handleDelete(uniLogos[2].univercity_id)}
                    className={`${Style["compare-remove"]}`}
                  >
                    <i className="uil uil-times-square" />
                  </div>
                )}
                <div className={`${Style["compare-img"]}`}>
                  {" "}
                  <img
                    src={`${baseUrl}${uniLogos[2].univercity_logo}`}
                    alt=""
                  />
                </div>
                {/* <div className={`${Style["compare-text"]}`}>
                  {" "}
                  ₹40000 per year onwards
                </div> */}
              </div>
            </div>
          ) : (
            <div
              className="col-4 col-md-3"
              data-toggle="modal"
              data-target="#allUniversityModal"
            >
              <div
                className={`${Style["compare-box"]} add-compare h-100 w-100 d-flex align-items-center justify-content-center flex-column`}
              >
                <div className={`${Style["compare-add-btn"]}  rounded-circle`}>
                  <i className="uil uil-plus" />
                </div>
                <div className={`${Style["compare-text"]}`}>
                  {" "}
                  Add To Compare
                </div>
              </div>
            </div>
          )}
          {/*----COl END----*/}
        </div>
        <div className="row compare-details mt-4 mb-4">
          <div className="col-4 col-md-3">
            <div className={Style.compare_feilds}>
              <ul className={Style["compare-list"]}>
                <li className={`${Style["edu-mode"]}  `}>Students Enrolled</li>
                <li className={`${Style["edu-mode"]}  `}>Student Rating</li>
                <li className={`${Style["edu-mode"]}  ${Style["approvals"]}`}>
                  Approvals
                </li>
                <li className={`${Style["edu-mode"]}  ${Style["approvals"]}`}>
                  Pros
                </li>
                <li className={`${Style["edu-mode"]}  `}>Education Mode</li>
                <li className={`${Style["edu-mode"]}  `}>Placement Record</li>
                <li className={`${Style["edu-mode"]}  ${Style["approvals"]}`}>
                  Eligibility
                </li>
                <li className={`${Style["edu-mode"]}  `}>NAAC Score</li>
                <li className={`${Style["edu-mode"]}  `}>NIRF Rank</li>
                <li className={`${Style["edu-mode"]}  `}>E-Learning</li>
                <li className={`${Style["edu-mode"]}  `}>Online Classes</li>
                <li className={`${Style["edu-mode"]}  `}>Satisfied Students</li>
                <li className={`${Style["edu-mode"]}  `}>Industry Ready</li>
                <li
                  className={`${Style["edu-mode"]} ${Style["sample-certificate"]}`}
                >
                  Sample Certificate
                </li>
                <li className={`${Style["edu-mode"]}  `}>EMI</li>
                <li className={`${Style["edu-mode"]}  `}>Placement</li>
              </ul>
            </div>
          </div>
          {uniDetails &&
            uniDetails.map((item, index) => (
              <div key={uniqid()} className="col-4 col-md-3">
                <div
                  className={`${Style["compare_feilds"]}  ${Style["compare_values"]}`}
                >
                  <ul className={`${Style["compare-values-list"]} `}>
                    <li
                      className={`${Style["edu-mode"]}   ${Style["students-enrolled"]}`}
                    >
                      <div
                        className={`${Style["radialProgressBar"]}  ${Style["progress-70"]}`}
                      >
                        <div className={`${Style["overlay"]} `}>
                          {item.students_enrolled}
                        </div>
                      </div>
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.students_rating}
                    </li>
                    <li
                      className={`${Style["edu-mode"]}  ${Style["approvals"]}`}
                    >
                      {item.approvals}
                    </li>
                    <li
                      className={`${Style["edu-mode"]}  ${Style["approvals"]}`}
                    >
                      {item.pros}
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.education_mode}
                    </li>
                    <li
                      className={`${Style["edu-mode"]} ${Style["yes-value"]}`}
                    >
                      <span>{item.placement_record} </span>
                    </li>
                    <li
                      className={`${Style["edu-mode"]}  ${Style["approvals"]}`}
                    >
                      {item.eligibility}
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.NAAC_score}
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.NIRF_rank}
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.e_learning}
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.online_classes}
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.satisfied_students}
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.industry_ready}
                    </li>
                    <li
                      className={`${Style["edu-mode"]} ${Style["sample-certificate"]} zoom-gallery`}
                    >
                      <Link
                        to={`${baseUrl}${item.certificate.replace(
                          "images/",
                          ""
                        )}`}
                        target="_blank"
                        className="popup-zoom"
                      >
                        <img
                          // src="/images/online-sample-certificate.jpg"
                          src={`${baseUrl}${item.certificate.replace(
                            "images/",
                            ""
                          )}`}
                          alt=""
                        />
                      </Link>
                    </li>
                    <li className={`${Style["edu-mode"]}  `}>{item.emi}</li>
                    <li className={`${Style["edu-mode"]}  `}>
                      {item.placement}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          <div
            className="modal fade"
            id="allUniversityModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    University List
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div
                  className="modal-body"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  {unilist
                    ?.filter(
                      (uni) => !universityIdsArray.includes(String(uni.id))
                    )
                    .map((item, index) => (
                      <div
                        key={index}
                        className="d-flex"
                        style={{ marginBottom: "16px" }}
                      >
                        <div className="col-8">
                          <p style={{ marginBottom: "0px" }}>{item.name}</p>
                        </div>
                        <div className="col-4">
                          <button
                            style={{
                              border: "none",
                              color: "#fff",
                              padding: "8px",
                              background: "brown",
                              borderRadius: "8px",
                            }}
                            data-dismiss="modal"
                            onClick={() => handleSelect(item.id)}
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompareSelect;
