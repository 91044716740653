import React from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";
import Parser from "../Elements/Parser";

export default function Faqs({ data, uniname }) {
  return (
    <div id="Faqs" className={`${CrStyle.University_Faq} my-5 pb-3`}>
      <div className="container" data-aos="fade-up" data-aos-delay={100}>
        <div className="row">
          <div className="col">
            <div className="text-left">
              <h2 className="section_title">Frequently Asked Questions (FAQ's) </h2>
            </div>
          </div>
        </div>
        <div className="row Faq_wrapp mt-4">
          <div className="col-lg-12">
            {/* Accordion */}
            <div
              id="accordionuniversity"
              className={` accordion ${CrStyle.accordion}`}
            >
              {data?.map((item, index) => (
                <div key={index} className={`${CrStyle.card} card `}>
                  <div
                    id="headingOne"
                    className={` ${CrStyle["card-header"]} card-header border-0`}
                  >
                    <div className="mb-0">
                      <div
                        type="button"
                        data-toggle="collapse"
                        data-target={`#content${index}`}
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        className={`${CrStyle["faq-btn"]}  faq-btn collapsible-link`}
                      >
                        <Link className={`${CrStyle["card-title"]} card-title`}>
                          Q - {item.question}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div
                    id={`content${index}`}
                    aria-labelledby="headingOne"
                    data-parent="#accordionuniversity"
                    className="collapse"
                  >
                    <div className={`${CrStyle["card-body"]} card-body p-3`}>
                      <p className="font-weight-light m-0">
                        <Parser content={item.answer}/>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* End */}
          </div>
        </div>
      </div>
    </div>
  );
}
