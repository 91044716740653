import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";
import Parser from "../Elements/Parser";
export default function WorthIt({data}) {
  return (
    <div
      id="Worth-it"
      className={`section ${Style.general_width} spec_worthit bg-white`}
    >
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col-12 col-md-12 mx-auto col">
            <div name="worthit" style={{ paddingTop: 70 }} className="row">
            <Parser content={data && data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
