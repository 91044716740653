import React, { useEffect, useState } from "react";
import CommonLayout from "../layouts/CommonLayout";
import TopForm from "../components/GeneralSpecial/TopForm";
import CourseValue from "../components/GeneralSpecial/CourseValue";
import SpecializationTable from "../components/GeneralSpecial/SpecializationTable";
import SubHeader from "../components/CourseComponents/SubHeader";
import KeyHighlights from "../components/GeneralSpecial/KeyHighlights";
import Subjects from "../components/GeneralSpecial/Subjects";
import Eligibility from "../components/GeneralSpecial/Eligibility";
import ProgramFee from "../components/GeneralSpecial/ProgramFee";
import AdmissionProcedure from "../components/GeneralSpecial/AdmissionProcedure";
import TopSpecialization from "../components/GeneralSpecial/TopSpecialization";
import LoanEmi from "../components/GeneralSpecial/LoanEmi";
import WorthIt from "../components/GeneralSpecial/WorthIt";
import CareerScope from "../components/GeneralSpecial/CareerScope";
import Faqs from "../components/CourseComponents/Faqs";
import ExpertAdvice from "../components/ExpertAdvice";
import InNews from "../components/InNews";
import NewsLetter from "../components/NewsLetter";
import uniqid from "uniqid";
import { apiBaseUrl } from "../config";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setGsc } from "../reducer/generalSpecializationSlice";
import TopUniversity from "../components/TopUniversity";
import UniversityLayout from "../components/UniversityLayout";

export default function GeneralSpecializationCourse() {
  const [genspeCourseDetailsData, setGenspeCourseDetailsData] = useState({});
  const { generalCourseName } = useParams();
  const dispatch = useDispatch();
  const courseDetails = useSelector((state) => state.generalSc?.gsc);
  const [loading, setLoading] = useState(true);

  const fetchGeneralCourse = async () => {
    try {
      const res = await fetch(`${apiBaseUrl}general-course-category-details/${generalCourseName}`);
      const data = await res.json();
      if (data) {
        dispatch(setGsc(data));
        setGenspeCourseDetailsData(data);
      } else {
        console.error("Invalid response format", data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);  // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    if (generalCourseName) {
      fetchGeneralCourse();
    }
  }, [generalCourseName]);

  useEffect(() => {
    fetchGeneralCourse();
  }, [generalCourseName]);

  const subheaderContent = [
    { title: "overview", link: "#overview" },
    { title: "Key Highlights", link: "#key-highlights" },
    { title: "Subject/Syllabus", link: "#subject" },
    { title: "Eligibility & Duration", link: "#eligibility" },
    { title: "Program Fees", link: "#program_fee" },
    { title: " Admission Procedure", link: "#Admission_procedure" },
    { title: "Top Specialization", link: "#Top_specialization" },
    { title: "Education Loan/EMIs", link: "#Loan_emi" },
    { title: "Worth It?", link: "#Worth-it" },
    { title: "Career Scope", link: "#Career_Scope" },
    { title: "Faq's", link: "#Faqs" },
  ];

  if (loading) {
    return <div>Loading...</div>;  // Optionally, show a loading spinner here
  }



  return (
    <CommonLayout>
      {courseDetails && (
        <>
          <TopForm key={uniqid()} data={courseDetails?.general_course_category_details} />
          <CourseValue key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details} />
          <SpecializationTable key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details}/>
          <SubHeader subheaderContent={subheaderContent} />
          {/* <Overview key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details?.overiew_content} /> */}
          <KeyHighlights key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details?.key_highlights} />
          <Subjects key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details?.subjects_syllabus} />
          <Eligibility key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details?.eligibility_duration} />
          <ProgramFee key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details} />
          <AdmissionProcedure key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details} />
          <TopSpecialization key={uniqid()} data={genspeCourseDetailsData.top_specialization} />
          <LoanEmi key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details?.education_loan_emi} />
          <WorthIt key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details?.worth_it} />
          <CareerScope key={uniqid()} data={genspeCourseDetailsData?.general_course_category_details?.career_scope} />
          <Faqs key={uniqid()} data={genspeCourseDetailsData.faq} />
          <ExpertAdvice getModal="#modalLoginForm2" />
          <InNews />
          <UniversityLayout key={uniqid()} data={genspeCourseDetailsData?.univercities} />
          <NewsLetter />
        </>
      )}
    </CommonLayout>
  );
}
