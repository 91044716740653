import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";

export default function ReadMoreBtn({
  onClick,
  condition = false,
  className = `summ-read-more ${Style["show-more"]}`,
}) {
  return (
    <>
      <button className={className} title="Read More" onClick={onClick}>
        {condition === true ? "Read Less" : "Read More"}
      </button>
    </>
  );
}
