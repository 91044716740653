import React from 'react'
import Style from "../../assets/styles/ModuleStyle/course.module.css"
import { baseUrl } from '../../config'
export default function ExpertWrapper({data}) {
  return (
    <div className={Style.expert_wrapper}>
    <div className="container" data-aos="fade-in">
      <div className="row">
        <div className="col-md-5">
          <div className={Style.grid}>
            <figure className={Style["effect-terry"]}>
              <img src={`${baseUrl}${data?.image}`} alt="" />
              <figcaption>
                <h4 style={{wordSpacing:"3.4px"}}>
                  {/* Masum <span>Manna</span> */}
                  {/* <span>{data?.name}</span> */}
                  {data?.name}
                </h4>
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="col-md-7">
          <div className="expert_details">
            <div className={`${Style.exp_col} d-flex flex-row align-items-center justify-content-around`}>
              <div className={`${Style["exprt-des"]} ${Style["border-right"]} border-right`}>
                {" "}
                <p> Designation</p>{" "}
                <p>
                  <strong> {data?.designation}</strong>
                </p>{" "}
              </div>
              <div className={`${Style["exprt-des"]}`}>
                {" "}
                <p> Experience </p>
                <p>
                  <strong> {data?.experience} </strong>{" "}
                </p>{" "}
              </div>
            </div>
            <div className={`${Style.exp_col} d-flex flex-row align-items-center justify-content-around`}>
              <div className={`${Style["exprt-des"]} ${Style["border-right"]} border-right`}>
                {" "}
                <div className={`${Style.rating_r} ${Style.rating_r_4}`}>
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                </div>{" "}
              </div>
              <div className={`${Style["exprt-des"]}`}>
                {" "}
                <p>
                  <a href>
                    <strong> Student Review </strong>{" "}
                  </a>
                </p>{" "}
              </div>
            </div>
          </div>
          <div className="expert_achivment mt-2">
            <h3 className="color-red text-center">Achivements</h3>
            <div className="row advantages achivements">
              <div className={`${Style["achive-col"]} col-6`}>
                <h5 className="text-center fs-6">Tele Counselling</h5>
                <p className="text-center">{data?.tele_counselling}</p>
              </div>
              <div className={`${Style["achive-col"]} col-6`}>
                <h5 className="text-center fs-6">Face To Face</h5>
                <p className="text-center">{data?.face_to_face}</p>
              </div>
              <div className={`${Style["achive-col"]} col-6`}>
                <h5 className="text-center fs-6">Career Build</h5>
                <p className="text-center">{data?.career_build}</p>
              </div>
              <div className={`${Style["achive-col"]} col-6`}>
                <h5 className="text-center fs-6">Student Rating</h5>
                <p className="text-center">{data?.student_rating}/10</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
