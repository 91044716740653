import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import Parser from "../Elements/Parser";

export default function CareerScope({data}) {
  return (
    <div
      id="Career_Scope"
      className={`section ${Style.general_width} spec_career bg-light`}
    >
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col-12 col-md-12 mx-auto col">
            <div
              name="career_scope"
              style={{ paddingTop: 50 }}
              className="pb-2 row"
            >
              <div className="py-3 col">
              <Parser content={data && data} />
              </div>
            </div>
            <p className="rounded d-inline-block w-auto fw-bold h5 m-0">
              Our students work at
            </p>
            <p className="my-3">
              <img
                className="img-fluid"
                alt="online mba grades"
                src="/images/mba-top-hiring.jpg"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
