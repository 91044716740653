import React from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";
import { baseUrl } from "../../config";

export default function CourseSideBar({ details }) {
  return (
    <div className={CrStyle.sidebar}>
      {/* Feature */}
      <div className={CrStyle["sidebar_section"]}>
        <div className={`${CrStyle.ads_sidebar} Ads_banner_one`}>
          <img
            className="img-fluid"
            src={`${baseUrl}${details?.image1}`}
            alt=""
          />
        </div>
      </div>
      {/* Feature */}
      <div className={CrStyle["sidebar_section"]}>
        <div className={CrStyle["uc-content"]}>
          <div className="uc-content-heading">
            <h3>{details?.name}</h3>
            <span className={CrStyle.call_side}>
              <i className="uil uil-calling" />{" "}
              <Link to="tel:+918692956126">8692 956 126</Link>
            </span>
          </div>
          <p>
            Get in Touch with Authorised {details?.name} Representative on Call
            for Complete details
          </p>
          <ul>
            <li>Connect with authorised representative</li>
            <li>Pay Fee directly to University</li>
            <li>Get Instant Discounts &amp; Offers</li>
            <li>EMI &amp; Loan Facilities Available</li>
          </ul>
          <h4 className={CrStyle["heading-blue"]}>
            <Link to="#" data-toggle="modal" data-target="#modalLoginForm2">
              Get Best Deal
            </Link>
          </h4>
        </div>
      </div>
      {/* Latest Course */}
      <div className={CrStyle["sidebar_section"]}>
        <div className={CrStyle["sidebar_section_title"]}>
          Course Buyers Tool
        </div>
        <div className={CrStyle.sidebar_latest}>
          {/* Latest Course */}
          <div
            className={`${CrStyle["latest"]} d-flex flex-row align-items-start justify-content-start`}
          >
            <div className={CrStyle.latest_image}>
              <img src="/images/buyers-tool-1.png" alt="" />
            </div>
            <div className={CrStyle.latest_content}>
              <div className={CrStyle.latest_title}>Course Loan</div>
              <div className={CrStyle.latest_buyers}>
                Apply &amp; Get Best Course Loan &amp; EMI
              </div>
            </div>
          </div>
          {/* Latest Course */}
          <div
            className={`${CrStyle["latest"]} d-flex flex-row align-items-start justify-content-start`}
          >
            <div className={CrStyle.latest_image}>
              <img src="/images/buyers-tool-2.png" alt="" />
            </div>
            <div className={CrStyle.latest_content}>
              <div className={CrStyle.latest_title}>Compare University</div>
              <div className={CrStyle.latest_buyers}>
                Now Compare Multiple university to choose the best
              </div>
            </div>
          </div>
          {/* Latest Course */}
          <div
            className={`${CrStyle["latest"]} d-flex flex-row align-items-start justify-content-start`}
          >
            <div className={CrStyle.latest_image}>
              <img src="/images/buyers-tool-3.png" alt="" />
            </div>
            <div className={CrStyle.latest_content}>
              <div className={CrStyle.latest_title}>Find the Right Course</div>
              <div className={CrStyle.latest_buyers}>
                Now Find the Right Course for you
              </div>
            </div>
          </div>
          {/* Latest Course */}
          <div
            className={`${CrStyle["latest"]} d-flex flex-row align-items-start justify-content-start`}
          >
            <div className={CrStyle.latest_image}>
              <img src="/images/buyers-tool-4.png" alt="" />
            </div>
            <div className={CrStyle.latest_content}>
              <div className={CrStyle.latest_title}>Speak to an Expert</div>
              <div className={CrStyle.latest_buyers}>
                Confused ? Don’t worry we are there to help
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feature */}
      {/* {details?.image2 !== "" || details?.image2 !== null ? (
        <div
          className={`${CrStyle.sidebar_section}  ${CrStyle["sidebar-item"]}`}
        >
          <div
            className={`${CrStyle["make-me-sticky"]}  ${CrStyle.ads_sidebar} Ads_banner_two`}
          >
            <img
              className="img-fluid"
              src={`${baseUrl}${details?.image2}`}
              alt=""
            />
          </div>
        </div>
      ) : (
        ""
      )} */}
    </div>
  );
}
