import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  featuredUniversity: {},
  particularUniversity: [],
  onlineUniversity: [],
  distanceUniversity: [],
};

export const featuredUniversitySlice = createSlice({
  name: "featuredUni",
  initialState,
  reducers: {
    getFeaturedUniversity: (state, action) => {
      state.featuredUniversity = action.payload;
    },
    setPartUni: (state, action) => {
      state.particularUniversity = action.payload;
    },
    setOnlineUni: (state, action) => {
      state.onlineUniversity = action.payload;
    },
    setDistanceUni: (state, action) => {
      state.distanceUniversity = action.payload;
    },
  },
});

export const {
  getFeaturedUniversity,
  setPartUni,
  setDistanceUni,
  setOnlineUni,
} = featuredUniversitySlice.actions;
export default featuredUniversitySlice.reducer;
