import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  expertAdviceList:[]
};

export const expertAdviceSlice = createSlice({
  name: "expertadvice",
  initialState,
  reducers: {
    setExpertAdvice:(state,action)=>{
        state.expertAdviceList=action.payload
    }
  },
});

export const {
  setExpertAdvice
} = expertAdviceSlice.actions;
export default expertAdviceSlice.reducer;
