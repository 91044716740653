import React from 'react'
import Style from "../../assets/styles/ModuleStyle/course.module.css"
export default function LogoSlider() {
  return (
    <div className={Style["logo-slider"]}>
    <div className={Style["slide-track"]}>
      <div className={Style.slide}>
        <img src="/images/logo/Amritaug.png" alt="" />
      </div>
      <div className={Style.slide}>
        <img src="/images/logo/CU.png" alt="" />
      </div>
      <div className={Style.slide}>
        <img src="/images/logo/dypatil-pune.png" alt="" />
      </div>
      <div className={Style.slide}>
        <img src="/images/logo/ICFAI-university.png" alt="" />
      </div>
      <div className={Style.slide}>
        <img src="/images/logo/jain.jpg" alt="" />
      </div>
      <div className={Style.slide}>
        <img src="/images/logo/MAHE.png" alt="" />
      </div>
      <div className={Style.slide}>
        <img src="/images/logo/nmims.png" alt="" />
      </div>
      <div className={Style.slide}>
        <img src="/images/logo/Amritaug.png" alt="" />
      </div>
    </div>
  </div>
  )
}
