import React from 'react'
import Style from "../../assets/styles/ModuleStyle/course.module.css"
export default function OpenModalCommon({text="Schedule an Appointment",modalId="#modalLoginForm2"}) {
  return (
    <div className={`${Style.confused_wrap} rounded-6`}>
        <div className="container" data-aos="fade-in">
          <div className="row">
            <div className="col-md-12">
              <div className={`${Style["expert-btn"]} ${Style.advice_wrap}`}>
                <div id={Style.e_loan} className="mb-3 text-center">
                  <a
                    type="button"
                    data-toggle="modal"
                    data-target={modalId}
                    className="btn-outline-dark"
                  >
                    {text}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
