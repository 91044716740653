import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css"
import { Link } from "react-router-dom";
import Parser from "../Elements/Parser";
export default function KeyHighlights({data}) {
  return (
    <div id="key-highlights" className={`section ${Style["general_width"]} spec_highlight`}>
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col">
            <div
              name="key_highlight"
              style={{ paddingTop: 70 }}
              className="mb-5 row"
            >
              <div className="col-12 col-lg-12 col">
              <Parser content={data && data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
