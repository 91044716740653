import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  university: [],
  universityId: "",
  modalDynamicImg: "",
  bannerArr: [],
};

export const universitySlice = createSlice({
  name: "particularUniversity",
  initialState,
  reducers: {
    setUniversity: (state, action) => {
      state.university = action.payload;
    },
    setUniversityId: (state, action) => {
      state.universityId = action.payload;
    },
    setModalDynamicImg: (state, action) => {
      state.modalDynamicImg = action.payload;
    },
    setBannerArr: (state, action) => {
      state.bannerArr = action.payload;
    },
  },
});

export const { setUniversity, setUniversityId, setModalDynamicImg,setBannerArr } =
  universitySlice.actions;
export default universitySlice.reducer;
