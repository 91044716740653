import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import owlStyle from "../assets/styles/ModuleStyle/customOwl.module.css";
import { Link } from "react-router-dom";
import uniqid from "uniqid";
import { useDispatch, useSelector } from "react-redux";
import { setExpertReview } from "../reducer/expertReviewsSlice";
import { apiBaseUrl, baseUrl } from "../config";
import { setUniversityNews } from "../reducer/UniversityNewsSlice";
import parser from "html-react-parser";
function LatestUpdates({ condition }) {
  const [uniNewsList, setUniNewsList] = useState();
  const [exptList, setExptList] = useState();
  const [tabFeed, setTabFeed] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const expertList = useSelector(
    (state) => state.expertReviews?.expertReviewList
  );
  const universitynews = useSelector(
    (state) => state.universityNews?.universityNewsList
  );

  useEffect(() => {
    if (condition && universitynews) {
      setUniNewsList([
        ...universitynews?.filter(
          (item, index) => item.universities_id === Number(condition) && item
        ),
      ]);
    } else {
      setUniNewsList(universitynews);
    }
  }, [condition, universitynews]);

  useEffect(() => {
    if (condition && expertList) {
      setExptList([
        ...expertList?.filter(
          (item, index) => item.universities_id === Number(condition) && item
        ),
      ]);
    } else {
      setExptList(expertList);
    }
  }, [condition, expertList]);

  const fetchExpertReviews = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}expert-review-list`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          // setUniversity(data);
          dispatch(setExpertReview(data && data.review_list));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const fetchUniversityNews = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}univercity-news`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          dispatch(setUniversityNews(data && data.univercity_news));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchExpertReviews();
    fetchUniversityNews();
  }, []);

  return (
    <>
      <div
        id="Latest_Update"
        className="Latest_Update Featured-University section"
      >
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h2 className="section_title">Latest University Updates</h2>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-in" data-aos-delay={100}>
            {/* Nav tabs */}
            <div className="card">
              <ul
                id="myTab"
                role="tablist"
                className="nav nav-tabs nav-pills bg-light border-0 rounded-nav"
              >
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div
                    id="uni-news-tab"
                    // data-toggle="tab"
                    // to="#uni-news"
                    role="tab"
                    // aria-controls="uni-news"
                    // aria-selected="true"
                    className={
                      tabFeed === 1
                        ? "nav-link border-0 font-weight-bold active"
                        : "nav-link border-0 font-weight-bold "
                    }
                    onClick={() => setTabFeed(1)}
                  >
                    UNIVERSITY NEWS
                  </div>
                </li>
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <div
                    id="uni-expert-tab"
                    // data-toggle="tab"
                    // to="#uni-expert"
                    role="tab"
                    // aria-controls="uni-expert"
                    // aria-selected="false"
                    className={
                      tabFeed === 2
                        ? "nav-link border-0 font-weight-bold active"
                        : "nav-link border-0 font-weight-bold "
                    }
                    onClick={() => setTabFeed(2)}
                  >
                    EXPERT REVIEWS
                  </div>
                </li>
              </ul>
              <div id="myTabContent" className="tab-content">
                {tabFeed === 1 && (
                  <div
                    id="uni-news"
                    role="tabpanel"
                    aria-labelledby="uni-news-tab"
                    className={
                      tabFeed === 1
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                  >
                    {loading ? (
                      <h3>Loading...</h3>
                    ) : (
                      <OwlCarousel
                        id="University_news"
                        className="owl-carousel owl-theme row"
                        loop
                        nav
                        dots={false}
                        stageOuterClass={owlStyle.owlStageOuterSpec}
                        navText={[
                          "<i class='fa fa-chevron-left'></i>",
                          "<i class='fa fa-chevron-right'></i>",
                        ]}
                        navContainerClass={owlStyle.containerNav}
                        navClass={["navPrev", "navNext"]}
                        items={3}
                        responsive={{
                          0: {
                            items: 1,
                          },
                          600: {
                            items: 2,
                          },
                          1000: {
                            items: 3,
                          },
                        }}
                      >
                        {uniNewsList &&
                          uniNewsList.map((item, index) => (
                            <div
                              key={uniqid()}
                              className="expertwrap thumbnail item col fea_university_col"
                              data-aos="fade-in"
                              data-aos-delay={100}
                            >
                              <div className="fev-colleges">
                                <div className="fea-university_image">
                                  <img src={baseUrl + item.image} alt="" />
                                </div>
                                <div className="fea-university_body">
                                  <h3 className="fea-university_title">
                                    <Link to="#">{item.title}</Link>
                                  </h3>
                                </div>
                                <div className="fea-university_footer">
                                  <div className="publishcol fea-university_footer_content d-flex flex-row align-items-center justify-content-between">
                                    <p>By: {item.published_by}</p>
                                    <p className="timeby">
                                      {item.ago} min. ago.
                                    </p>
                                  </div>
                                  <div className="blog-content">
                                    <p>
                                      {item.content &&
                                      typeof item.content === "string"
                                        ? parser(item.content)
                                        : null}
                                    </p>
                                    {/* {item.content} */}
                                  </div>
                                  <div className="fea-university-button">
                                    <Link to="" className="btn fea-button">
                                      Read More{" "}
                                      <i className="uil uil-angle-right" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </OwlCarousel>
                    )}
                  </div>
                )}
                {tabFeed === 2 && (
                  <div
                    id="uni-expert"
                    role="tabpanel"
                    aria-labelledby="uni-expert-tab"
                    className={
                      tabFeed === 2
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                    }
                  >
                    {loading ? (
                      <h3>Loading...</h3>
                    ) : (
                      <OwlCarousel
                        id="University_experts"
                        className="owl-carousel owl-theme row"
                        loop
                        nav
                        dots={false}
                        stageOuterClass={owlStyle.owlStageOuterSpec}
                        navText={[
                          "<i class='fa fa-chevron-left'></i>",
                          "<i class='fa fa-chevron-right'></i>",
                        ]}
                        navContainerClass={owlStyle.containerNav}
                        navClass={["navPrev", "navNext"]}
                        items={3}
                        responsive={{
                          0: {
                            items: 1,
                          },
                          600: {
                            items: 2,
                          },
                          1000: {
                            items: 3,
                          },
                        }}
                      >
                        {exptList &&
                          exptList.map((item, index) => (
                            <div
                              key={uniqid()}
                              className="expertwrap thumbnail item col fea_university_col"
                              data-aos="fade-in"
                              data-aos-delay={100}
                            >
                              <div className="fev-colleges">
                                <div className="expert-wrap fea-university_image">
                                  <Link to={item.video_url}>
                                    <img
                                      src={`${baseUrl}${item.video_thumbnail}`}
                                      alt=""
                                    />
                                  </Link>
                                  <div className="youtube-icon">
                                    <Link to={item.video_url}>
                                      <i className="uil uil-youtube" />
                                    </Link>
                                  </div>
                                </div>
                                <div className="fea-university_body">
                                  <h3 className="fea-university_title">
                                    <Link to="#">{item.title}</Link>
                                  </h3>
                                </div>
                                <div className="fea-university_footer">
                                  <div className="publishcol fea-university_footer_content d-flex flex-row align-items-center justify-content-between">
                                    <p>By: {item.posted_by}</p>
                                    <p className="timeby">
                                      {item.ago} min. ago.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </OwlCarousel>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(LatestUpdates);
