import React from "react";
import Style from '../../assets/styles/ModuleStyle/course.module.css'
import Parser from "../Elements/Parser";

export default function LoanEmi({data}) {
  return (
    <div id="Loan_emi" className={`section ${Style.general_width} spec_loanemi bg-blue`}>
      <div className="container" data-aos="fade-in">
        <div className="row">
          <div className="col-12 col-md-12 mx-auto col">
            <div
              name="education_loan"
              style={{ paddingTop: 70 }}
              className="pb-5 row text-white"
            >
              <Parser content={data && data} />
              <div className="d-flex justify-content-center">
                <div id={Style.e_loan} className="w-75 mt-5 mb-3">
                  <a
                    type="button"
                    data-toggle="modal"
                    data-target="#modalLoginForm2"
                    className=""
                  >
                    Need an Educational Loan Apply to get Instant Loan{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
