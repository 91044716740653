import React from "react";
import ReactOwlCarousel from "react-owl-carousel";
import owlStyle from "../../assets/styles/ModuleStyle/customOwl.module.css";
import { Link } from "react-router-dom";
import uniqid from "uniqid";
import { baseUrl } from "../../config";


function FeaturedCarousel({list}) {

  // const [list, setList] = useState([]);

  // useEffect(() => {
  //   if (uniList && uniList.length > 0) {
  //     setList([...uniList]); // Spread operator to create a new array
  //   }
  // }, [uniList]);

  return (
    <ReactOwlCarousel
      id="popular-ug"
      className="owl-carousel owl-theme row"
      loop
      nav
      dots={false}
      stageOuterClass={owlStyle.owlStageOuterSpec}
      navText={[
        "<i class='fa fa-chevron-left'></i>",
        "<i class='fa fa-chevron-right'></i>",
      ]}
      navContainerClass={owlStyle.containerNav}
      navClass={["navPrev", "navNext"]}
      items={3}
      responsive={{
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      }}
    >
      {list.length > 0 &&
        list.map((item, index) => (
          <div key={uniqid()} className="thumbnail item col fea_university_col">
            <div className="fev-colleges">
              <div className="fea-university_year text-right ml-auto">
                {item.established}
              </div>
              <div className="fea-university_image">
                <img src={`${baseUrl}${item.logo}`} alt="" />
              </div>
              <div className="fea-university_body">
                <h3 className="fea-university_title">
                  <Link to="#">{item.name}</Link>
                </h3>
              </div>
              <div className="fea-university_footer">
                <div className="fea-university_footer_content d-flex flex-row align-items-center justify-content-between">
                  <div className="fea-university_info">
                    <div className="fea-university_state">
                      <i className="fa fa-graduation-cap" aria-hidden="true" />
                      {item.state}
                    </div>
                  </div>
                  <div className="fea-university_info">
                    <div className="fea-university_text">
                      <i className="fa fa-star" aria-hidden="true" />
                      {item.university_type.split(" ")[0]}
                    </div>
                  </div>
                </div>
                <div className="fea-university-button">
                  <Link className="btn fea-button" to={`/university/${item.name}`}>
                    View More About University
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
    </ReactOwlCarousel>
  );
}

export default FeaturedCarousel;
