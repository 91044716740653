import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inNewsData:[]
};

export const inNewsSlice = createSlice({
  name: "InNews",
  initialState,
  reducers: {
    setInNewsData:(state,action)=>{
        state.inNewsData=action.payload
    }
  },
});

export const {
    setInNewsData
} = inNewsSlice.actions;
export default inNewsSlice.reducer;
