import React, { useEffect, useState } from "react";
import CommonLayout from "../layouts/CommonLayout";
import Breadcumbs from "../components/BlogComponents/Breadcumbs";
import Style from "../assets/styles/ModuleStyle/course.module.css";
import BlogDetails from "../components/BlogComponents/BlogDetails";
import OpenModalCommon from "../components/Elements/OpenModalCommon";
import BlogSideBar from "../components/BlogComponents/BlogSideBar";
import Blogs from "../components/Blogs";
import ExpertAdvice from "../components/ExpertAdvice";
import NewsLetter from "../components/NewsLetter";
import { apiBaseUrl } from "../config";
import { useParams } from "react-router-dom";

export default function BlogsPage() {
  const [blogDetails, setBlogDetails] = useState([]);
  const [recentPost, setRecentPost] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const { title } = useParams();
  const fetchBlogDetails = () => {
    // setLoading(true);
    fetch(`${apiBaseUrl}blog-details/${title}`)
      .then((res) => res.json())
      .then((data) => {
        // setLoading(false);
        if (data.status === "success") {
          setBlogDetails(data.blog_details);
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error(error);
      });

  };
  const fetchRecentPost = () => {
    fetch(`${apiBaseUrl}recent-post`)
      .then((res) => res.json())
      .then((data) => {
        // setLoading(false);
        if (data.status === "success") {
          setRecentPost([...data.recent_blog]);
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error(error);
      });
  };

  const fetchCategory = () => {
    fetch(`${apiBaseUrl}blog-category-list`)
      .then((res) => res.json())
      .then((data) => {
        // setLoading(false);
        if (data.status === "success") {
          setCategoryList([...data.blog_category_list]);
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    fetchRecentPost();
    fetchCategory();
  }, []);

  useEffect(() => {
    if (title) {
      fetchBlogDetails();
    }
  }, [title]);
  return (
    <CommonLayout>
      <Breadcumbs />
      <div className={`${Style.blog} blog`}>
        <div className="container">
          <div className="row">
            {/* Blog Content */}
            <div className="col-lg-9">
              <BlogDetails blogDetails={blogDetails && blogDetails} />
              {/*-----Faq Section-----*/}
              {/* <Faqs /> */}
              {/*---- Get Advice from Expert !-----*/}
              <OpenModalCommon
                text="Confused ? Get Advice from Expert !"
                modalId="#modalLoginForm"
              />
            </div>
            {/* Blog Sidebar */}
            <div className="col-lg-3">
              <BlogSideBar
                isMba={true}
                recentPost={recentPost && recentPost}
                categoryList={categoryList && categoryList}
              />
            </div>
          </div>
        </div>
      </div>
      <Blogs />
      <ExpertAdvice />
      <NewsLetter />
    </CommonLayout>
  );
}
