import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allUniversity:[]
};

export const allUniversitySlice = createSlice({
  name: "alluniversitylist",
  initialState,
  reducers: {
    setAllUniversityList:(state,action)=>{
        state.allUniversity=action.payload
    }
  },
});

export const {
    setAllUniversityList
} = allUniversitySlice.actions;
export default allUniversitySlice.reducer;
