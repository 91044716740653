import React, { useEffect, useState } from "react";

import OwlCarousel from "react-owl-carousel";
import owlStyle from "../assets/styles/ModuleStyle/customOwl.module.css";
import { Link } from "react-router-dom";
import { apiBaseUrl, baseUrl } from "../config";
import { setCompareUniversity } from "../reducer/compareUniversitySlice";
import { useDispatch, useSelector } from "react-redux";
import { getShortForm } from "../utils/utils";
import uniqid from "uniqid";
export default function CompareUniversity() {
  const dispatch = useDispatch();
  const comparelist = useSelector((state) => state.compare?.compareData);
  const [loading, setLoading] = useState(false);
  const fetchCompareList = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}compare-univercity`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data) {
          // setUniversity(data);
          dispatch(setCompareUniversity(data));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchCompareList();
  }, []);

  return (
    <>
      <div className="Compare section">
        <div className="container" data-aos="fade-in">
          <div className="row">
            <div className="col pb-3">
              <div className="section_title text-left">
                <h2 className="section_title">Compare Universities</h2>
              </div>
            </div>
          </div>
          <div className="row" data-aos="fade-in">
            {loading ? (
              <h3>Loading...</h3>
            ) : (
              <OwlCarousel
                loop
                nav
                dots={false}
                stageOuterClass={owlStyle.owlStageOuterSpec}
                navText={[
                  "<i class='fa fa-chevron-left'></i>",
                  "<i class='fa fa-chevron-right'></i>",
                ]}
                navContainerClass={owlStyle.containerNav}
                navClass={["navPrev", "navNext"]}
                id="compare_university"
                className="owl-carousel owl-theme row"
                items={3}
                responsive={{
                  0: {
                    items: 1,
                  },
                  600: {
                    items: 2,
                  },
                  1000: {
                    items: 3,
                  },
                }}
              >
                {comparelist &&
                  comparelist.map((item, index) => (
                    <div
                      key={uniqid()}
                      className="thumbnail item col fea_university_col"
                      data-aos="fade-in"
                      data-aos-delay={100}
                    >
                      <div className="fev-colleges">
                        <div className="compare-body d-flex flex-row align-items-center justify-content-between">
                          <div className="compare-box border-right">
                            <div className="fea-university_image">
                              <img src={`${baseUrl}${item.left_logo}`} alt="" />
                            </div>
                            <div className="fea-university_footer_content d-flex flex-row align-items-center justify-content-center">
                              <div className="fea-university_info">
                                <div className="fea-university_state">
                                  <i
                                    className="fa fa-graduation-cap"
                                    aria-hidden="true"
                                  />
                                  {item.left_state}
                                </div>
                              </div>
                              <div className="fea-university_info">
                                <div className="fea-university_text">
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                  {item.left_univercity_type}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="compare-box">
                            <div className="fea-university_image">
                              <img
                                src={`${baseUrl}${item.right_logo}`}
                                alt=""
                              />
                            </div>
                            <div className="fea-university_footer_content d-flex flex-row align-items-center justify-content-center">
                              <div className="fea-university_info">
                                <div className="fea-university_state">
                                  <i
                                    className="fa fa-graduation-cap"
                                    aria-hidden="true"
                                  />
                                  {item.right_state}
                                </div>
                              </div>
                              <div className="fea-university_info">
                                <div className="fea-university_text">
                                  <i
                                    className="fa fa-star"
                                    aria-hidden="true"
                                  />
                                  {item.right_univercity_type}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fea-university_footer">
                          <div className="fea-university-button">
                            <Link className="btn fea-button" to={`/comparison/${item.left_univercity_id},${item.right_univercity_id}`}>
                              {item.compare_text}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
