import React from 'react'
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";

export default function UgcAdd({imgSrc}) {
  return (
    <div id="UG_small_ADS" className={CrStyle.university_ADs}>
    <div className="row">
      <img
        className={CrStyle["img-responsive"]}
        // src="/images/ads/small-banner.jpg"
        src={imgSrc && imgSrc}
        alt=""
      />
    </div>
  </div>
  )
}
